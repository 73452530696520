import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Footer from '../Camponent/NavBarFooter/Footer'
import Banner from '../Camponent/BlogFour/Banner'

function BlogFour() {
  return (
    <div>
        <NavBar/>
        <Banner/>
        <Footer/>
    </div>
  )
}

export default BlogFour