import React, { useRef, useState } from 'react'
import { useAuth } from '../../Context/ContextProvider'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
function LocationPurpose({ load, data, setData, setlatitude, latitude, fieldErrors, setCityValue, city_value }) {
    const [type, setType] = useState([])
    const [category, setCategory] = useState([])
    const [sub_category, setsub_category] = useState([])
    const [city, setcity] = useState([])
    const [allcity, setallcity] = useState([])
    const [location, setLocation] = useState([])
    const parms = useParams()
    const { getType, getcateGory, getsubCateGory, getCity, getLOcation } = useAuth()
    useEffect(() => {
        const fetchData = async () => {
            try {
                let result = await getType();
                if (result?.success) {
                    const propertyTypes = result.data.data.property_types;
                    setType(propertyTypes);

                    const selectedTypeId = data?.type || propertyTypes[0]?.id;
                    let result1 = await getcateGory(selectedTypeId);
                    if (result1?.success) {
                        const categories = result1.data.data.categories;
                        setCategory(categories);

                        const selectedCategoryId = data?.property_type || categories[0]?.id;
                        let result2 = await getsubCateGory(selectedCategoryId);
                        if (result2?.success) {
                            setsub_category(result2.data.data.sub_categories);

                            // Update the data state based on the fetched results
                            setData(prevData => ({
                                ...prevData,
                                type: selectedTypeId,
                                property_type: selectedCategoryId,
                                property_sub_type: data?.property_sub_type || result2.data.data.sub_categories[0]?.id
                            }));
                        }
                    }
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        const fetchData1 = async () => {
            try {
                let result = await getType();
                if (result?.success) {
                    const propertyTypes = result.data.data.property_types;
                    setType(propertyTypes);
                    const selectedTypeId = data?.type;
                    let result1 = await getcateGory(selectedTypeId);
                    if (result1?.success) {
                        const categories = result1.data.data.categories;
                        setCategory(categories);

                        const selectedCategoryId = data?.property_type;
                        let result2 = await getsubCateGory(selectedCategoryId);
                        if (result2?.success) {
                            setsub_category(result2.data.data.sub_categories);
                        }
                    }
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };

        const fetchCity = async () => {
            try {
                let result = await getCity();
                if (result?.success) {
                    setcity(result.data);
                    setallcity(result.data);
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        if (parms.id) {
            fetchData1();
        }
        else {
            if(!type?.length){
                fetchData();
            }
        }
        if(!allcity?.length){
            fetchCity();
        }
        // eslint-disable-next-line
    }, [parms?.id, load]);
    const [show, setShow] = useState(false)
    const [show1, setShow1] = useState(false)
    const wrapperRef = useRef(null);
    // Close the dropdown if clicked outside
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShow1(false);
            setShow(false);
            // if (!data?.city_id) {
            //     setCityValue((prev) => ({
            //         ...prev,
            //         city: "", // Clear city
            //     }));
            // }
    
            // if (!data?.state_id) {
            //     setCityValue((prev) => ({
            //         ...prev,
            //         location: "", // Clear location
            //     }));
            // }
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line
    }, []);
 
    
    return (
        <div className='locationPurpos'>
            <div className='d-flex align-items-center mb-3'>
                <svg className='location_svg' xmlns="http://www.w3.org/2000/svg" width="19" height="23" viewBox="0 0 19 23" fill="none">
                    <path d="M9.04196 20.4217C9.50123 20.4217 9.91194 20.1835 10.1405 19.7835C12.2492 16.0978 15.7863 9.50208 15.7863 6.74446C15.7863 3.02505 12.7601 0 9.04183 0C5.32241 0 2.29736 3.02615 2.29736 6.74446C2.29736 9.50106 5.83436 16.0978 7.94314 19.7835C8.17169 20.1835 8.5827 20.4217 9.04196 20.4217ZM9.04196 1.15043C12.1274 1.15043 14.637 3.66016 14.637 6.74543C14.637 8.53824 12.5833 13.1987 9.14215 19.2132C9.09794 19.2908 8.98583 19.2908 8.94163 19.2132C5.5015 13.1987 3.44681 8.53821 3.44681 6.74543C3.44681 3.65994 5.9567 1.15043 9.04196 1.15043Z" fill="#B7B7B7" />
                    <path d="M17.9703 21.3331L16.1279 17.3733C15.9371 16.9615 15.5199 16.6963 15.066 16.6963H14.0268C13.2679 16.7103 13.2732 17.8336 14.0268 17.8466L15.0854 17.8584L16.9085 21.8483L1.15481 21.8181L3.01662 17.8465H4.05586C4.37387 17.8465 4.63045 17.5888 4.63045 17.2719C4.63045 16.9539 4.3728 16.6973 4.05586 16.6973H3.01662C2.56276 16.6973 2.14556 16.9636 1.95472 17.3743L0.112313 21.3341C-0.259629 22.0758 0.343018 23.0256 1.17421 22.9997H16.9085C17.7376 23.0245 18.3445 22.0769 17.9704 21.3341L17.9703 21.3331Z" fill="#B7B7B7" />
                    <path d="M13 7.00358C13 4.7928 11.2023 3 8.98526 3C3.67499 3.18318 3.66817 10.8153 8.98526 11C11.2022 11 13 9.20734 13 7.00358Z" fill="#2797D4" />
                </svg>

                <h4 className='new_heading'>Location & Purpose</h4>
            </div>

            <h6 className='h6 new_sub_heading'>Select Purpose</h6>
            <div className='d-flex'>
                {type?.map((item) => {
                    return (
                        <button onClick={async () => {
                            let result1 = await getcateGory(item?.id)
                            if (result1?.success) {
                                setCategory(result1?.data?.data?.categories)
                                let result2 = await getsubCateGory(result1?.data?.data?.categories[0]?.id)
                                if (result2?.success) {
                                    setsub_category(result2?.data?.data?.sub_categories)
                                    setData({ ...data, type: item?.id, property_type: result1?.data?.data?.categories[0]?.id, property_sub_type: result2?.data?.data?.sub_categories[0]?.id ,slug:item?.slug})
                                }
                            }
                        }
                        } className={`${data?.type === item?.id ? "active_btn" : ""}`} style={{marginRight:'12px'}}>{item?.name}</button>
                    )
                })}
            </div>
            <h6 className='new_sub_heading mt-3 mb-0'>Select Property Type</h6>

            <div className='tab_lable  mb-1'>
                {category?.map((item) => {
                    return (
                        <p onClick={async () => {
                            let result2 = await getsubCateGory(item?.id)
                            if (result2?.success) {
                                setsub_category(result2?.data?.data?.sub_categories)
                                setData({
                                    ...data,
                                    property_type: item?.id,
                                    property_sub_type: result2?.data?.data?.sub_categories[0]?.id
                                })
                            }
                        }} className={`${item?.id === data?.property_type ? "active_lable new_haeding" : "new_heading"}`} style={{marginRight:'16px'}}>{item?.name}</p>
                    )
                })}
            </div>
            <div className='d-flex scroll-box'>
                {sub_category?.map((item) => {
                    // return item?.category_id === data?.property_type && item?.data?.map((items) => {
                    return (
                        <button onClick={() => {
                            setData({ ...data, property_sub_type: item?.id })
                        }} className={`${item?.id === data?.property_sub_type ? "active_btn" : ""} `} style={{marginRight:'12px'}}>{item?.name}</button>
                    )
                    // })
                })}
            </div>
            <div className="row mb-2">
                <div className="col-lg-5 col-md-6 col-12" ref={wrapperRef}>
                    <div className='inp_login'>
                        <label className="logo_label">City</label>
                        <input onClick={() => setShow1(true)} value={city_value?.city} onChange={async (e) => {
                            setCityValue({ ...city_value, city: e.target.value })
                            if (e.target.value) {
                                setShow1(true)
                                let array = allcity?.filter((item) => item?.city?.toLowerCase().includes(e.target.value?.toLowerCase()));

                                setcity(array)
                            }
                            else {
                                setShow1(false)
                                setcity(allcity)
                            }

                        }} placeholder='Select Location' />
                        {show1 && <ul ref={wrapperRef}>
                            {city?.map((item) => {
                                return (
                                    <li onClick={() => {
                                        setCityValue({ ...city_value, city: item?.city })
                                        setData({ ...data, city_id: item?.app_code })
                                        setShow1(false)

                                    }}>{item?.city}</li>
                                )
                            })}
                        </ul>}
                        {fieldErrors?.city_id && <span className='text_error'>{fieldErrors?.city_id}</span>}
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 col-12" ref={wrapperRef}>
                    <div className='inp_login'>
                        <label className="logo_label">Location</label>
                        <input value={city_value?.location} onChange={async (e) => {
                            setCityValue({ ...city_value, location: e.target.value })
                            if (e.target.value) {
                                setShow(true)
                            }
                            else {
                                setShow(false)
                            }
                            let result = await getLOcation({
                                city_code: data?.city_id,
                                keyword: e.target.value,
                            });
                            if (result?.success) {
                                setLocation(result?.data.status === false ? [] : result?.data);
                            }
                        }} placeholder='Select Location' />
                        {show && <ul ref={wrapperRef}>
                            {location?.map((item) => {
                                return (
                                    <li onClick={() => {
                                        setCityValue({ ...city_value, location: item?.name })
                                        setData({ ...data, state_id: item?.id })
                                        setShow(false)
                                        setlatitude(item?.geo_location)
                                    }}>{item?.name}</li>
                                )
                            })}
                        </ul>}
                        {fieldErrors?.state_id && <span className='text_error'>{fieldErrors?.state_id}</span>}
                    </div>
                </div>
            </div>
            <iframe className='new_location_map' title='map' src={`https://maps.google.com/maps?q=${latitude?.lat},${latitude?.lng}&z=15&output=embed`} width="83%" height="320" style={{ border: "0", borderRadius: "5px" , margin:'10px 0 22px 0'  }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <div className="row">
                <div className="col-lg-5 col-md-6 col-12">
                    <div className='inp_login'>
                        <label className="logo_label">Plot Number</label>
                        <input value={data?.plot_number} onChange={(e) => setData({ ...data, plot_number: e.target.value })} placeholder='Plot No' type='text' />
                        { <span className='text_error'>{fieldErrors?.plot_number}</span>}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default LocationPurpose
