import React, { useState } from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/HomePage/Banner'
import PopularProperties from '../Camponent/HomePage/PopularProperties'
import ListingProperty from '../Camponent/HomePage/ListingProperty'
import OurProject from '../Camponent/HomePage/OurProject'
import Footer from '../Camponent/NavBarFooter/Footer'
import SeoSection from '../Camponent/HomePage/SeoSection'
import NeighborhoodProperties from '../Camponent/HomePage/NeighborhoodProperties'
import { Helmet } from 'react-helmet'

function HomePage() {
  const [propertyList,setProperty]=useState([])
  const [propertyListAll,setPropertyAll]=useState([])

    const realEstateAgentSchema = {
      "@context": "https://schema.org",
      "@type": "RealEstateAgent",
      "name": "Pakistan Property",
      "image": "https://i.ibb.co/n3HKjKC/450839369-455413067248547-5453049016825158074-n.jpg",
      "@id": "https://pakistanproperty.com/#RealEstateAgent",
      "url": "https://pakistanproperty.com",
      "telephone": "0305-1115551",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Building No. 21, 3rd Floor, Block-CCA, DHA Phase 8 - Ex Park View Phase-8 Lahore",
        "addressLocality": "Lahore",
        "postalCode": "54000",
        "addressCountry": "PK"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": 31.524679,
        "longitude": 74.4343428
      },
      "openingHoursSpecification": {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday"
        ],
        "opens": "00:00",
        "closes": "23:59"
      }
    };
  
    const organizationSchema = {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Pakistan Property",
      "alternateName": "Real Estate Agency",
      "url": "https://pakistanproperty.com",
      "logo": "https://i.ibb.co/n3HKjKC/450839369-455413067248547-5453049016825158074-n.jpg",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "0305-1115551",
        "contactType": "customer service",
        "areaServed": "PK",
        "availableLanguage": ["en", "Urdu"]
      },
      "sameAs": [
        "https://www.facebook.com/pakistanproperty.com.official",
        "https://x.com/Pak_Pro_",
        "https://www.instagram.com/_pakistanproperty_",
        "https://www.youtube.com/@PakistanPropertyOfficial",
        "https://www.linkedin.com/company/pakistanproperty",
        "https://www.pinterest.com/pakistanpropertyofficial",
        "https://pakistanproperty.com"
      ]
    };
  
    const websiteSchema = {
      "@context": "https://schema.org/",
      "@type": "WebSite",
      "name": "Property Pakistan",
      "url": "https://pakistanproperty.com/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://pakistanproperty.com/search?q={search_term_string}",
        "query-input": "required name=search_term_string"
      }
    };
  return (
    <div>
      <Helmet>
      <title>Real Estate Agency - Your Trusted Partner in Pakistan</title>
      <script type="application/ld+json">{JSON.stringify(realEstateAgentSchema)}</script>
        <script type="application/ld+json">{JSON.stringify(organizationSchema)}</script>
        <script type="application/ld+json">{JSON.stringify(websiteSchema)}</script>
      <meta name="description"
    content="Looking for real estate investment in Pakistan? Our trusted real estate agency connects overseas Pakistanis with top properties at affordable rates today!"/>
      </Helmet>
           {/* <ClientProject/> */}
        {/* <Agent/> */}
        <NavBar/>
        <Banner setPropertyAll={setPropertyAll} setPropertyData={setProperty}/>
        <PopularProperties setPropertyAll={setPropertyAll} propertyListAll={propertyListAll} propertyList={propertyList}  setPropertyData={setProperty}/>
        <ListingProperty data={propertyList}/>
        <OurProject/>
   
        <NeighborhoodProperties/>
        <SeoSection/>
        <Footer/>

    </div>
  )
}

export default HomePage
