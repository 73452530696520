import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Footer from '../Camponent/NavBarFooter/Footer'
import Banner from '../Camponent/Blogtwo/Banner'

function BlogTow() {
  return (
    <div>
        <NavBar/>
        <Banner/>
        <Footer/>
    </div>
  )
}

export default BlogTow