import React, { useState, useRef, useEffect } from 'react'
import img from '../../Images/Vector (4).png'
import img1 from '../../Images/Vector (5).png'
import img2 from '../../Images/Vector (6).png'
import img3 from '../../Images/Vector (7).png'
import logo from '../../Images/property1/pakistan-property-logo-new-color.svg'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import { TbCurrentLocation } from 'react-icons/tb'
import { useAuth } from '../../Context/ContextProvider'
import { FaChevronDown } from 'react-icons/fa'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  // p: 4,
};




function Instant() {

  const {allcity, cityData, getLOcation, currentCity, instant } = useAuth()

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);

    setData(
      {
        name: "",
        email: "",
        contact: "",
        contact_option: "",
        city: "",
        location_id: "",
        additional_info: ""
      }

    )
    city_value.city = '';
    city_value.location = '';
    setFieldErrors({})

  }
  const [fieldErrors, setFieldErrors] = React.useState({})
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [cityDataShow,setCityDataShow]=useState([])

  const [location, setLocation] = useState([])

  const [city_value, setCityValue] = useState({
    city: currentCity?.city_name,
    city_code: currentCity?.app_code,
    location: "",
  })
  const wrapperRef = useRef(null);

 useEffect(()=>{
  setCityDataShow(cityData)
 },[cityData])
  const [data, setData] = useState({
    name: "",
    email: "",
    contact: "",
    contact_option: 'phone',
    city: city_value.city,
    location_id: city_value?.location,
    additional_info: ""
  })

  console.log(data.contact_option, "data")

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  console.log(currentCity.app_code, 'user')


  const isValidPakistaniPhoneNumber = (phoneNumber) => {

    let cleanedPhoneNumber = phoneNumber.replace(/[^\d\+]/g, '');


    const pakistaniPhoneRegex = /^(?:0|\+92)?\s?(?:\d\s?){9,10}$/;

    const isValid = pakistaniPhoneRegex.test(cleanedPhoneNumber);

    return isValid;
  };


  const formatPakistaniPhoneNumber = (phoneNumber) => {
    let phone = phoneNumber.replace(/\D/g, ''); // Remove non-digit characters

    // Handle different input formats
    if (phone.startsWith('92')) {
      phone = phone.slice(2);
    }
    else if (phone.startsWith('+92')) {
      phone = phone.slice(3);
    }
    else if (phone.startsWith('0')) {
      phone = phone.slice(1);
    }

    // Ensure the phone number is no longer than 10 digits
    if (phone.length > 10) {
      phone = phone.slice(0, 10);
    }
    return phone; // Return processed phone number
  };



  const validateStep = () => {
    let errors = {

    };
    if (!data.name) {
      errors.name = "Name is required.";
    }
    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!data.contact) {
      errors.contact = "Contact is required.";
    }
    else if (!isValidPakistaniPhoneNumber(data.contact)) {
      errors.contact = "Please enter a valid  phone number.";
    }
    // if (!data.contact_option) {
    //   errors.contact_option = "Contact way is required.";
    // }
    if (!city_value.city) {
      errors.city = "City is required.";
    }
    if (!city_value.location) {
      errors.location = "Location is required.";
    }
    // if (!data.additional_info) {
    //   errors.additional_info = "Additional info is required.";
    // }
    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };


  const submit = async (event) => {
    event.preventDefault();
    if (validateStep()) {
      let result = await instant({
        city_code: data.city_code,
        location_id: data.location_id,
        phone: `+92${data.contact}`,
        name: data.name,
        email: data.email,
        way_to_contact: data.contact_option,
        additional_information: data.additional_info
      });
      if (result?.success) {
        setData(
          {
            name: "",
            email: "",
            contact: "",
            contact_option: "",
            city: "",
            location_id: "",
            additional_info: ""
          }

        )
        city_value.city = '';
        city_value.location = '';
        setFieldErrors({})
      }

      console.log(data)
    }
  }

  const handleCheckboxChange = (value, isChecked) => {
    setData((prevData) => {
      // If checked, add the value, otherwise remove it
      let contact_option = prevData.contact_option.split(',').map((item) => item.trim()).filter(Boolean); // Convert to array
      if (isChecked) {
        // Add the value if it's checked, without duplicates
        if (!contact_option.includes(value)) {
          contact_option.push(value);
        }
      } else {
        // Remove the value if it's unchecked
        contact_option = contact_option.filter((item) => item !== value);
      }

      // Convert the array back to a string, formatted as 'value1', 'value2'
      contact_option = contact_option.map((item) => `${item}`).join(',');
      console.log(contact_option)

      return { ...prevData, contact_option };
    });
  };




  const handleCitySearch = (e) => {
    const cityQuery = e.target.value;
    setCityValue({ city: e.target.value, location: "" });
    // Filter city data based on user input
    if (cityQuery) {
      setShow(true);
      let filteredCities = allcity.filter((item) =>
        item?.city?.toLowerCase().includes(cityQuery.toLowerCase())
      );
      setCityDataShow(filteredCities);

      // If no cities are found, clear the location input
   
    } else {
      // Reset city data and clear the location when search is cleared
      setShow(false);
      setCityDataShow(allcity); // Reset city data when search is cleared
      // Clear the location input when no search query
    }
  };


  // Effect to fetch locations when the city changes
  useEffect(() => {
    if (data.city_code) {
      const fetchLocations = async () => {
        let result = await getLOcation({
          city_code: data.city_code,
          keyword: city_value.location, // Optional: use keyword to filter locations
        });
        if (result?.success) {
          setLocation(result?.data?.status === false ? [] : result?.data);
        }
      };
      fetchLocations();
    }
  }, [data.city_code, city_value.location]);

  // Function to handle city selection
  const handleCitySelect = (item) => {
    // When a city is selected, set the city code and clear location
    setCityValue({ ...city_value, city: item.city, location: "", city_code: item.app_code })
    setData({ ...data, city_code: item.app_code });
    setShow(false); // Close the dropdown
    setLocation([]); // Clear the locations (optional)
    setCityValue({ city: item.city, code: item.code });
    if (item && item.city && item.code) {
      console.log("Selected City:", item.city);
      console.log("City Code (app-code or city-code):", item.code);
    } else {
      console.log("City or Code is missing in the selected item.");
    }
    // city_value.city_code=item.code
  };

  // Handle location search/input change
  const handleLocationSearch = async (e) => {
    const locationQuery = e.target.value;
    setCityValue({ ...city_value, location: locationQuery });

    if (locationQuery) {
      setShow1(true); // Show the location dropdown
      let result = await getLOcation({
        city_code: data?.city_code,
        keyword: locationQuery,
      });
      if (result?.success) {
        setLocation(result?.data.status === false ? [] : result?.data);
      }
    } else {
      setShow1(false); // Hide the location dropdown if input is cleared
      setLocation([]); // Clear location data if input is empty
    }
  };

  // Location item selection from dropdown
  const handleLocationSelect = (item) => {
    setCityValue({ ...city_value, location: item.name });
    setData({ ...data, location_id: item.id });
    setShow1(false); // Close the location dropdown
  };







  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShow1(false);
      setShow(false);

      if (!data?.city_code && !data?.location) {
        // If no city_code and location are available, set default city and empty location
        setCityValue({
          city: currentCity?.city_name || "Lahore",
          location: "",
          city_code: currentCity?.city_code || "PP016 "
        });
      } else if (!data?.city_code) {
        // If no city_code is available, only set city (use the default or current one)
        setCityValue({
          ...city_value,
          city: currentCity?.city_name || "Lahore",
          city_code: currentCity?.city_code || "PP016 "
        });
      } else {
        // Otherwise, just clear the location if city_code exists
        setCityValue({
          ...city_value,
          location: "",
        });
      }
    }
  };




  useEffect(() => {

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  })


  return (
    <>
      <div className='instant mx-sm-4 mx-3 mt-5'>
        <div className='row mx-0 mt-0'>
          <div className='col-lg-5'>
            <div className='value'>
              <div className='row'>
                <div className='col-lg-12 p-0'>
                  <div className='value-box instantValueBox flex-sm-nowrap flex-wrap  d-flex  justify-content-sm-start justify-content-center'>
                    <div className='v-card'>
                      <div className='instant-card'>
                        <h3>Instant<br />results</h3>
                        <div className='v-img'>
                          <img src={img} alt='...' />
                        </div>
                      </div>
                      <p>See your results now. No waiting around. Get your home value estimate immediately.</p>
                    </div>
                    <div style={{ background: '#F1FFFF' }} className='v-card mt-sm-0 mt-3'>
                      <div className='instant-card mt-sm-0 mt-2'>
                        <h3>Data-driven<br />accuracy</h3>
                        <div style={{ background: '#2797D4' }} className='v-img'>
                          <img src={img1} alt='...' />
                        </div>
                      </div>
                      <p>It’s accurate & reliable. We use the latest market data & advanced algorithms, so you can trust our estimates every time.</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 p-0'>
                  <div className='value-box instantValueBox flex-sm-nowrap flex-wrap d-flex  justify-content-sm-start justify-content-center'>
                    <div style={{ background: '#F1FFFF' }} className='v-card'>
                      <div className='instant-card'>
                        <h3>No<br />obligation</h3>
                        <div style={{ background: '#2797D4' }} className='v-img'>
                          <img src={img2} alt='...' />
                        </div>
                      </div>
                      <p>Try it for free, with no strings attached. No cost, no commitment. Use it as often as you need.</p>
                    </div>
                    <div className='v-card  mt-sm-0 mt-3'>
                      <div className='instant-card'>
                        <h3>100%<br />Online</h3>
                        <div className='v-img'>
                          <img src={img3} alt='...' />
                        </div>
                      </div>
                      <p>Get your home Value instantly, anytime, anywhere. No appointments, no paperwork. Just quick, convenient online access.</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className='col-lg-7'>
            <div className='intant-value my-4'>
              <h2>Get an Instant<br/> Online Valuation of <br/> Your Property</h2>
              <p>Try our advanced online property valuation tool</p>
              <h5>Prefer a personalized approach? Our local agents are readily available to 
complement your instant valuation with expert insights and a detailed 
discussion of your property’s value.</h5>
              <button onClick={handleOpen} >Get an Agent Valuation</button>
            </div>
          </div>
        </div>
      </div>
      <Modal

        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='instant_main_modal'>
          <div className="instant_modal">
            <div className="container-fluid p-0 m-0">
              <div className="row m-0">
                <div className="col-12 p-0 d-flex justify-content-center">
                  <div className="company_img">
                    <img src={logo} alt="" />
                  </div>
                </div>
                <div className="col-12 p-0">
                  <div className="text">Time is precious, so you can ask agents to get in touch when you’d like them to.</div>
                </div>
                <div className="col-12 p-0">
                  <div className="instant_input_form scroll-box-y">
                    <div className="inp_login">
                      <label>Full Name</label>
                      <input type="text" placeholder='Full Name' value={data?.name} onChange={(e) => setData({ ...data, name: e.target.value })} />
                      {fieldErrors?.name && <span className='text_error'>{fieldErrors?.name}</span>}
                    </div>
                    <div className="inp_login">
                      <label>Email</label>
                      <input type="text" placeholder='E-mail' value={data?.email} onChange={(e) => setData({ ...data, email: e.target.value })} />
                      {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                    </div>
                    <div className="inp_login">
                      <label>Contact</label>
                      <div className='contact_contry'>
                        <div className='img_container'>
                          <img src='./image/Group.png' alt='...' />
                          <span>+92</span>
                        </div>
                        <input style={{ borderRadius: " 0 5px 5px 0 ", borderLeft: "none" }} maxLength={10} type='text' placeholder='e.g 3324751060' value={data?.contact} onChange={(e) => setData({ ...data, contact: formatPakistaniPhoneNumber(e.target.value) })} />
                        {fieldErrors?.contact && <span className='text_error'>{fieldErrors?.contact}</span>}
                      </div>
                    </div>

                    <div className="inp_login">
                      <label>Best way to contact you (optional)</label>
                      <div className="input_container d-flex" style={{ gap: "20px" }}>
                        <div className="inp_login mb-0 d-flex align-items-center">
                          <Checkbox
                            checked={data.contact_option.includes('phone')} // Controlled value
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 20,
                                stroke: "lightgray",
                                strokeWidth: 0.2,
                                fill: "#2797D4",
                                borderRadius: "6px",
                              },
                              "&.Mui-checked": {
                                color: "#2797D4",
                              },
                            }}
                            value={'phone'}
                            onChange={(e) => handleCheckboxChange('phone', e.target.checked)}
                          // checked={data.contact_option=== "PHONE"}
                          />
                          <label>Phone</label>
                        </div>
                        <div className="inp_login mb-0 d-flex align-items-center">
                          <Checkbox
                            checked={data.contact_option.includes('email')} // Controlled value
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 20,
                                stroke: "lightgray",
                                strokeWidth: 0.2,
                                fill: "#2797D4",
                                borderRadius: "6px",
                              },
                              "&.Mui-checked": {
                                color: "#2797D4",
                              },
                            }}
                            value={'email'}
                            onChange={(e) => handleCheckboxChange('email', e.target.checked)}
                          />
                          <label>Email</label>
                        </div>
                        {fieldErrors?.contact_option && (
                          <span className="text_error">{fieldErrors?.contact_option}</span>
                        )}
                      </div>
                    </div>
                    <div className="inp_login" onClick={() => setShow(true)}>
                      <label>City</label>
                      <div
                        className="d-flex align-items-center pe-2"
                        style={{
                          position: "relative",
                          border: "1px solid lightgray",
                          borderRadius: "5px",
                        }}
                      >
                        <input
                          style={{ border: "none" }}
                          value={city_value.city}
                          onChange={handleCitySearch}
                          
                          type="text"
                          placeholder="Search City"
                        />
                        <FaChevronDown style={{ color: "lightgray", marginTop: "5px", marginRight: "5px" }} />
                      </div>
                      {show && (
                        <ul ref={wrapperRef} style={{ position: "absolute", top: "110%", color: "black", textAlign: "start" }}>
                          {/* Check if cityData is available */}
                          {cityDataShow?.length === 0 ? (
                            <div style={{ height: "30px", width: "100%", overflow: 'hidden' }}>
                              <p style={{ height: "8px" }} className="placeholder-glow row">
                                <span className="placeholder col-12 h-100 rounded"></span>
                              </p>
                              <p style={{ height: "8px" }} className="placeholder-glow row">
                                <span className="placeholder col-12 h-100 rounded"></span>
                              </p>
                              <p style={{ height: "8px" }} className="placeholder-glow row">
                                <span className="placeholder col-12 h-100 rounded"></span>
                              </p>
                            </div>
                          ) : (
                            cityDataShow?.map((item) => (
                              <li key={item.code}

                                onClick={() => {
                                  handleCitySelect(item);
                                }}
                              >
                                {item?.city}
                              </li>
                            ))
                          )}
                        </ul>
                      )}

                      {fieldErrors?.city && <span className='text_error'>{fieldErrors?.city}</span>}
                    </div>

                    <div className="inp_login">
                      <label>Location</label>
                      <div
                        className={
                          !city_value?.city
                            ? "disabled-input-box d-flex align-items-center px-2"
                            : "d-flex align-items-center px-2"
                        }
                        style={{
                          position: "relative",
                          border: "1px solid lightgray",
                          borderRadius: "5px",
                        }}
                        onClick={city_value?.city ? () => setShow1(true) : undefined}
                      >
                        <TbCurrentLocation className="target_icon mt-1" />
                        <input
                          disabled={!city_value?.city}
                          style={{ border: "none" }}
                          value={city_value?.location}
                          onClick={() => setShow1(true)}
                          onChange={handleLocationSearch}
                          type="search"
                          placeholder="Search by location"
                        />
                        <FaChevronDown style={{ color: "lightgray", marginTop: "5px", marginRight: "5px" }} />
                      </div>
                      {show1 && (
                        <ul ref={wrapperRef} style={{ position: "absolute", top: "110%", color: "black", textAlign: "start" }}>
                          {/* Check if location data is available */}
                          {location?.length === 0 ? (
                            <div style={{ height: "30px", width: "100%", overflow: 'hidden' }}>
                              <p style={{ height: "8px" }} className="placeholder-glow row">
                                <span className="placeholder col-12 h-100 rounded"></span>
                              </p>
                              <p style={{ height: "8px" }} className="placeholder-glow row">
                                <span className="placeholder col-12 h-100 rounded"></span>
                              </p>
                              <p style={{ height: "8px" }} className="placeholder-glow row">
                                <span className="placeholder col-12 h-100 rounded"></span>
                              </p>
                            </div>
                          ) : (
                            location?.map((item) => (
                              <li key={item.code}

                                onClick={() => {
                                  handleLocationSelect(item);
                                }}
                              >
                                {item?.name}
                              </li>
                            ))
                          )}
                        </ul>
                      )}


                      {fieldErrors?.location && <span className='text_error'>{fieldErrors?.location}</span>}
                    </div>
                    <div className="inp_login">
                      <label>Additional information (optional)</label>
                      <textarea placeholder='Write here' value={data?.additional_info} onChange={(e) => setData({ ...data, additional_info: e.target.value })} />
                      {fieldErrors?.additional_info && <span className='text_error'>{fieldErrors?.additional_info}</span>}
                    </div>
                  </div>
                  <div className="col-12 p-0">
                    <div className="submit_btn" onClick={submit}>
                      Submit
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default Instant
