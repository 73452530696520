import React, { useState } from 'react';
import NavBar from '../Camponent/NavBarFooter/NavBar';
import Banner from '../Camponent/Listingproperty/Banner';
import Listing from '../Camponent/Listingproperty/Listing';
import Map from '../Camponent/Blogsingle/Map';
import Footer from '../Camponent/NavBarFooter/Footer';
import { useAuth } from '../Context/ContextProvider';
import { Helmet } from 'react-helmet';
import SeoSection from '../Camponent/Listingproperty/SeoSection';

// Import Schema JSON files
import rentSchemaBase from '../schemas/Property_for_Rent_Schemas.json';
import leaseSchemaBase from '../schemas/Property_for_Lease_Schemas.json';
import saleSchemaBase from '../schemas/Property_for_Sale_Schemas.json';
import { lease_data, rent_data, sell_data } from '../Camponent/NavBarFooter/dummayData';

function Listingproperty() {
  const { propertyList, data, setData } = useAuth();
  const [pagination, setPagination] = useState("");
  const [city_value, setCityValue] = useState({ city: "", location: "" });
  const [property_name, setPropertyName] = useState();

  const path = window?.location?.pathname?.split('/')[2];

  // Clone base schema to avoid modifying imported JSON
  let schemaData = path === "property-for-rent" 
    ? JSON.parse(JSON.stringify(rentSchemaBase)) 
    : path === "property-for-lease" 
    ? JSON.parse(JSON.stringify(leaseSchemaBase)) 
    : path === "property-for-sell" 
    ? JSON.parse(JSON.stringify(saleSchemaBase)) 
    : null;

  // Dynamically update schema with propertyList data
  if (schemaData && propertyList.length > 0) {
    schemaData.itemListElement = propertyList.map((property, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": `https://pakistanproperty.com/property/${property.id}/${property.title.replace(/\s+/g, '_')}`,
      "item": {
        "@type": "Product",
        "name": property.title,
        "description": property.description || "No description available.",
        "image": property.image || "https://pakistanproperty.com/default-image.jpg",
        "offers": {
          "@type": "Offer",
          "price": property.price ? property.price.toString() : "0",
          "priceCurrency": "PKR",
          "availability": "https://schema.org/InStock",
          "url": `https://pakistanproperty.com/property/${property.id}/${property.title.replace(/\s+/g, '_')}`
        }
      }
    }));
  }

  return (
    <div>
      <Helmet>
        {path === 'property-for-sell' && <title>Properties for Sale - Find Your Home of Comfort Today</title>}
        {path === 'property-for-rent' && <title>Best Properties for Rent - Property Available for Rent</title>}
        {path === 'property-for-lease' && <title>Properties For Lease - Best Leased Property for Sale</title>}

        {path === 'property-for-sell' && <meta name="description"
          content="Properties for sale at unbeatable prices. Trusted property for sale in Pakistan listings tailored for overseas buyers. Find your perfect home now!" />}
        {path === 'property-for-rent' && <meta name="description"
          content="Looking for properties for rent? Find the ideal property available for rent for families or individuals. Begin your search and rent with ease today!" />}
        {path === 'property-for-lease' && <meta name="description"
          content="Explore properties for lease and find the ideal location. A leased property for sale might be the perfect choice for your investment needs!" />}

        {/* Inject Schema JSON-LD */}
        {schemaData && (
          <script type="application/ld+json">
            {JSON.stringify(schemaData)}
          </script>
        )}
      </Helmet>

      <NavBar />
      <Banner setPropertyName={setPropertyName} data={data} setData={setData} setPagination={setPagination} setCityValue={setCityValue} city_value={city_value} />
      <Listing property_name={property_name} city_value={city_value} data={propertyList} pagination={pagination} filterData={data} />
      <Map />
      {path === 'property-for-lease' && <SeoSection data={lease_data} />}
      {path === 'property-for-rent' && <SeoSection data={rent_data} />}
      {path === 'property-for-sell' && <SeoSection data={sell_data} />}
      <Footer />
    </div>
  );
}

export default Listingproperty;
