import React, { useState } from "react";
import { AiOutlineTikTok, AiOutlineTwitter } from "react-icons/ai";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPhone,
  FaPinterest,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { GoArrowRight } from "react-icons/go";
import { GrMail } from "react-icons/gr";
import { IoBriefcaseSharp } from "react-icons/io5";
// import logo from "../../Images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/ContextProvider";
import logo from '../../Images/p.png'
function Footer() {
  const navigate = useNavigate();
  const { newslater, type } = useAuth();
  const [value, setValue] = useState();
  const [errors, setError] = useState();
  const userData = JSON.parse(localStorage.getItem("agent_data"))
    ? JSON.parse(localStorage.getItem("agent_data"))
    : "";
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  return (
    <div  className="footer mt-5 ">
      <div className="d-flex justify-content-between flex-wrap flex-column flex-sm-row">
        <div className="footer_logo">
        <img style={{width:"200px"}} src={logo} alt="..."/>
        </div>
        <div className="social_link">
          <span>Follow Us</span>
          <span>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/profile.php?id=100083395718437"
            >
              <FaFacebookF />{" "}
            </a>
          
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/_pakistanproperty_/"
            >
              <FaInstagram />{" "}
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/pakistanproperty/"
            >
              <FaLinkedinIn />{" "}
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.tiktok.com/@pakistanproperty.com"
            >
              <AiOutlineTikTok />{" "}
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/@PakistanPropertyOfficial"
            >
              <FaYoutube />{" "}
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.pinterest.com/pakistanpropertyofficial/"
            >
              <FaPinterest />{" "}
            </a>
          </span>
        </div>
      </div>
      <hr style={{ borderColor: "gray", margin: "1rem 0" }} />
      <div className="row">
        <div className="col-lg-4 col-md-6 col-12 ">
          <h6 className="text-center text-sm-start" >Subscribe</h6>
          <div style={{ position: "relative" }} className="footer_inp">
            <input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              type="email"
              placeholder="Your E-mail"
              // 
            />
            <button
              onClick={() => {
                if (!value) {
                  setError("Please enter email");
                } else if (!isValidEmail(value)) {
                  setError("Please enter a valid email");
                } else {
                  newslater(value);
                  setValue("");
                  setError("");
                }
              }}
            >
              Send <GoArrowRight className="mx-2" />
            </button>
            {errors && (
              <span style={{ top: "150%" }} className="text_error footEmailErr">
                {errors}
              </span>
            )}
          </div>
          <hr className="d-none d-sm-block"/>
          <p  className="py-3 py-sm-0 mb-3">
          Subscribe to our newsletter to stay updated with our latest news and updates.
          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <h5  className="text-center text-sm-start" >Contact Us</h5>
          <ul  className="footerUL ">
            <li>
                <span>
                <FaWhatsapp className="me-2" />
                </span>
                <span>
                0326-8099990
                </span>
             
            </li>
            <li>
                <span>

              <FaPhone className="me-2" /> 
                </span>
                <span>
              0326-8099990

                </span>
            </li>
            <li>
                <span>

              <GrMail className="me-2" /> 
                </span>
                <span>
              hello@pakistanproperty.com

                </span>
            </li>
            <li>
                <span>

              <IoBriefcaseSharp className="me-2" />
                </span>
                <span>
               Mon-Sat 9:00 am to 10:00pm

                </span>
            </li>
            <li className="mb-1">
                <span>
              <FaLocationDot className="me-2" /> 
                </span>
                <span>
              Building No. 21, 3rd Floor,
              Block-CCA, DHA Phase 8 - Ex Park View Phase-8 Lahore, 54000.
                </span>
            </li>
          </ul>
        </div>
        <div className="col-lg-2 col-md-6 col-12">
          <h5  className="text-center text-sm-start" >Our Services</h5>

          <ul className="footerUL footerULNew">
            {userData && (
              <li
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/agent/add-property")}
              >
                Add Property
              </li>
            )}
            {type?.map((item, i) => {
              return (
                <>
                  {
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/property/property-for-${item?.slug}`)
                      }
                    >
                      {item?.name === "Sell" ? "Buy" : item?.name} Property
                    </li>
                  }
                </>
              );
            })}
            <li
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/intstant-valuation")}
            >
              Instant Valuation
            </li>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/agencies")}
            >
              Real Estate Agencies
            </li>
          </ul>
        </div>
        <div className="col-lg-2 col-md-6 col-12">
          <h5  className="text-center text-sm-start" >Quick Links</h5>
          <ul className="footerUL footerULNew">
            <li
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/about-us");
              }}
            >
              About
            </li>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact Us
            </li>
            {/* <li style={{cursor:"pointer"}} onClick={()=>{
                        navigate("/News")
                    }}>News</li> */}
            <Link style={{ textDecoration: "none" }} to="/faqs">
              <li
                style={{ cursor: "pointer" }}
                onClick={() => {
                  // navigate("/about-us")
                }}
              >
                FAQ’s
              </li>
            </Link>
            {/* <li style={{cursor:"pointer"}} onClick={()=>{
                        navigate("/Blogs")
                    }}>Blogs</li> */}
            <li
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/privacy-policy");
              }}
            >
              Privacy Policy
            </li>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/blogs");
              }}
            >
         Blogs
            </li>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/term-and-condition");
              }}
            >
              Terms & Conditions
            </li>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/sitemap");
              }}
            >
             Sitemap
            </li>
          </ul>
        </div>
      </div>
      <hr style={{ borderColor: "gray", margin: "1rem" }} />
      <p className="copy_right">
        © Copyright 2025 pakistanproperty.com. All Rights Reserved
      </p>
    </div>
  );
}

export default Footer;
