import React from 'react'
import { CiWarning } from 'react-icons/ci'
import { useNavigate, useParams } from 'react-router-dom'
import img from '../../Images/rb_4.png'
function Propertypending() {
    const parms=useParams()
    const navigate=useNavigate()
  return (
    <div className='row m-0 alert_page my-5'>
        <div className='col-8 m-auto p-0'>
            <div className='warnning_bar'>
                <div>
                    <div className='war_icon'>
                        <CiWarning />
                    </div>
                </div>
                <div className='mx-3'>
                    <h5 className='p-0 m-0'>Ad submitted but not live!</h5>
                    <p className='p-0 m-0'>Your porperty detail have been submitted,please purchase ad slot to make it visible to user!</p>
                </div>
            </div>
        </div>
        <div className='col-8 m-auto my-2 p-0'>
            <div className='warnning_dec'>
            <div className='row m-0'>
                <div className='col-lg-8 col-md-6 col-12'>
                <h5>Ad submitted but not live!</h5>
                <ul className='p-0'>
                    <li><p>Reach over a million active users on Pakistan#1 proerty portal</p></li>
                    <li><p>Get quality leads & sell or rent out your property faster</p></li>
                    <li><p>Reuse the same slot for your next property ad</p></li>
                </ul>
                <button onClick={()=>navigate('/agent/buy-product')}>Buy Now</button> <span style={{color:"lightgray",fontSize:"14px"}} className='mx-2'>OR</span> <button style={{background:"none",border:"1px solid #2797D4",color:"#2797D4"}}>Call Now +92 305 111 5551</button>
                <p style={{fontWeight:"bold"}} className='my-2'>Your ad ID is: {parms.id}.Refer it while talking to our sale rep</p>
                </div>
                <div className='col-lg-4 col-md-6 col-12'>
                   <img style={{width:"80%"}} src={img} alt='...'/>
                </div>
            </div>
            </div>
            
        </div>
    </div>
  )
}

export default Propertypending