import React, { useEffect, useRef, useState } from "react";
import dummyImage from "../../../Images/images.jfif";
import { TbCurrentLocation } from "react-icons/tb";
import { LuSave } from "react-icons/lu";
import flag from "../../../Images/listing/pak.png";
import { useAuth } from "../../../Context/ContextProvider";

function Profile() {
  const { userData, getCity, updateProfile, loading } = useAuth();
  const [city, setcity] = useState([]);
  const [allcity, setallcity] = useState([]);
  const [show1, setShow1] = useState(false);
  const [city_value, setCityValue] = useState({
    city: "",
  });
  useEffect(() => {
    const fetchCity = async () => {
      try {
        let result = await getCity();
        if (result?.success) {
          setcity(result.data);
          setallcity(result.data);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };
    fetchCity();
  }, []);
  const [data, setData] = useState({
    name: "",
    email: "",
    whatsapp: "",
    landline: "",
    mobile: "",
    city_code: "",
    address: "",
    image: "",
  });
  useEffect(() => {
    const userLOcalData = userData?.data?.user;
    setCityValue({ ...city_value, city: userLOcalData?.currentCity?.city });
    setData({
      ...data,
      name: userLOcalData?.name ? userLOcalData?.name : data?.name,
      mobile: userLOcalData?.phone_number
        ? formatPakistaniPhoneNumber(userLOcalData?.phone_number)
        : formatPakistaniPhoneNumber(data?.mobile),
      whatsapp: userLOcalData?.whatsapp_number
        ? formatPakistaniPhoneNumber(userLOcalData?.whatsapp_number)
        : formatPakistaniPhoneNumber(data?.whatsapp_number),
      landline: userLOcalData?.landline
        ? formatPakistaniPhoneNumber(userLOcalData?.landline)
        : formatPakistaniPhoneNumber(data?.landline),
      email: userLOcalData?.email ? userLOcalData?.email : data?.email,
      city_code: userLOcalData?.currentCity?.app_code
        ? userLOcalData?.currentCity?.app_code
        : data?.city_id,
      address: userLOcalData?.address ? userLOcalData?.address : data?.address,
      image: userLOcalData?.avatar ? userLOcalData?.avatar : data?.image,
    });
  }, []);
  const [fieldErrors, setFieldErrors] = React.useState({});
  const validateStep = () => {
    let errors = {};
    if (!data.name) {
      errors.name = "Name is required.";
    }
    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (!data.city_code) {
      errors.city_id = "City is required.";
    }
    if (!data.address) {
      errors.address = "Address is required.";
    }
    if (!data.mobile) {
      errors.mobile = "Mobile is required.";
    }
    if (!data.image) {
      errors.image = "Image is required.";
    } else if (!isValidPakistaniPhoneNumber("+92" + data.mobile)) {
      errors.mobile = "Please enter a valid  phone number.";
    }

    if (data?.landline) {
      if (data.landline?.length !== 9 && data.landline?.length !== 10) {
        errors.landline = "Please enter a valid  phone number.";
      }
    }
    if (!data.whatsapp) {
      errors.whatsapp = "whatsapp is required.";
    } else if (!isValidPakistaniPhoneNumber("+92" + data.whatsapp)) {
      errors.whatsapp = "Please enter a valid  phone number.";
    }
    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  const submit = (event) => {
    event.preventDefault();
    if (validateStep()) {
      updateProfile({
        ...data,
        whatsapp_number: "+92" + data?.whatsapp,
        phone_number: "+92" + data?.mobile,
        landline: data?.landline ? "+92" + data?.landline : "",
      });
    }
  };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        setData({ ...data, image: base64String });
      };

      reader.readAsDataURL(file);
    }
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const formatPakistaniPhoneNumber = (phoneNumber) => {
    let phone = phoneNumber?.replace(/\D/g, ""); // Remove non-digit characters

    // Handle different input formats
    if (phone?.startsWith("92")) {
      phone = phone?.slice(2);
    } else if (phone?.startsWith("+92")) {
      phone = phone?.slice(3);
    } else if (phone?.startsWith("0")) {
      phone = phone?.slice(1);
    }

    // Ensure the phone number is no longer than 10 digits
    if (phone?.length > 10) {
      phone = phone?.slice(0, 10);
    }
    return phone; // Return processed phone number
  };
  const isValidPakistaniPhoneNumber = (phoneNumber) => {
    // Remove all non-digit characters except '+'
    // eslint-disable-next-line
    let cleanedPhoneNumber = phoneNumber.replace(/[^\d\+]/g, "");

    // Log the cleaned phone number
    const pakistaniPhoneRegex = /^(?:0|\+92)?\s?(?:\d\s?){9,10}$/;
    // Regular expression for Pakistani phone number
    // const pakistaniPhoneRegex = /^\+92\d{11}$/;

    // Validate phone number format and length
    const isValid = pakistaniPhoneRegex.test(cleanedPhoneNumber);

    return isValid;
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);
  const wrapperRef = useRef(null);
  // Close the dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShow1(false);
      // if (!data?.city_id) {
      //     setCityValue((prev) => ({
      //         ...prev,
      //         city: "", // Clear city
      //     }));
      // }

      // if (!data?.state_id) {
      //     setCityValue((prev) => ({
      //         ...prev,
      //         location: "", // Clear location
      //     }));
      // }
    }
  };
  return (
    <>
      <div className="locationPurpos d-flex justify-content-between "
        
        
      >
        <div className="main_profile">
          <div className="d-flex justify-content-start w-100">
            <div className="profile_section" style={{ border: "none" }}>
              <div className="profile_img">
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={data?.image ? data?.image : dummyImage}
                  alt="..."
                />
              </div>
              <div className="user_data mt-md-0 mt-3">
                <h6 className="p-0 m-0">{data?.name}</h6>
                {userData?.data?.role === "agent" ? (
                  <p
                    style={{
                      border: "1px solid #2797D4",
                      width: "max-content",
                      padding: ".3rem 1rem",
                      borderRadius: "50px",
                      color: "#2797D4",
                      background: "#E3FEFF",
                    }}
                    className="my-1 invidual-btn"
                  >
                    Individual
                  </p>
                ) : (
                  <p
                    style={{
                      border: "1px solid #2797D4",
                      width: "max-content",
                      padding: ".3rem 1rem",
                      borderRadius: "50px",
                      color: "#2797D4",
                      background: "#E3FEFF",
                    }}
                    className="my-1 invidual-btn"
                  >
                    Agency
                  </p>
                )}
                <p className="p-0 my-1 invidual-btn">{data?.email}</p>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      <div className="locationPurpos my-3 ">
        <div className="modal_feature p-sm-3 p-0">
          <div>
            <div className="row my-2">
              <div className=" col-md-6 col-12">
                <div className="inp_login">
                  <label>Full Name</label>
                  <input
                    value={data?.name}
                    style={{
                      color: "gray",
                      cursor: "not-allowed",
                      background: "#F5F6FA",
                    }}
                    readOnly
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    type="text"
                    placeholder="Name"
                  />
                  {fieldErrors?.name && (
                    <span className="text_error">{fieldErrors?.name}</span>
                  )}
                </div>
              </div>
              <div className=" col-md-6 col-12">
                <div className="inp_login">
                  <label>Email</label>
                  <input
                    value={data?.email}
                    readOnly
                    style={{
                      color: "gray",
                      cursor: "not-allowed",
                      background: "#F5F6FA",
                    }}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                    type="email"
                    placeholder="Email"
                  />
                  {fieldErrors?.email && (
                    <span className="text_error">{fieldErrors?.email}</span>
                  )}
                </div>
              </div>

              <div className=" col-md-6 col-12">
                <div className="inp_login">
                  <label>Landline</label>
                  <div className="contact_contry">
                    <div className="img_container">
                      <img src={flag} alt="..." />
                      <span>+92</span>
                    </div>
                    <input
                      value={data?.landline}
                      maxLength={11}
                      onChange={(e) =>
                        setData({
                          ...data,
                          landline: formatPakistaniPhoneNumber(e.target.value),
                        })
                      }
                      style={{
                        borderRadius: " 0 5px 5px 0 ",
                        borderLeft: "none",
                      }}
                      type="text"
                      placeholder="e.g 3324751060"
                    />
                  </div>
                  {fieldErrors?.landline && (
                    <span className="text_error">{fieldErrors?.landline}</span>
                  )}
                </div>
              </div>
              <div className=" col-md-6 col-12">
                <div className="inp_login">
                  <label>Mobile</label>
                  <div className="contact_contry">
                    <div className="img_container">
                      <img src={flag} alt="..." />
                      <span>+92</span>
                    </div>
                    <input
                      value={data?.mobile}
                      maxLength={10}
                      onChange={(e) =>
                        setData({
                          ...data,
                          mobile: formatPakistaniPhoneNumber(e.target.value),
                        })
                      }
                      style={{
                        borderRadius: " 0 5px 5px 0 ",
                        borderLeft: "none",
                      }}
                      type="text"
                      placeholder="e.g 3324751060"
                    />
                  </div>
                  {fieldErrors?.mobile && (
                    <span className="text_error">{fieldErrors?.mobile}</span>
                  )}
                </div>
              </div>
              <div className=" col-md-6 col-12">
                <div className="inp_login">
                  <label>Whatsapp</label>
                  <div className="contact_contry">
                    <div className="img_container">
                      <img src={flag} alt="..." />
                      <span>+92</span>
                    </div>
                    <input
                      value={data?.whatsapp}
                      maxLength={10}
                      onChange={(e) => {
                        const formattedNumber = formatPakistaniPhoneNumber(
                          e.target.value
                        );
                        setData({ ...data, whatsapp: formattedNumber });
                      }}
                      style={{
                        borderRadius: " 0 5px 5px 0 ",
                        borderLeft: "none",
                      }}
                      type="text"
                      placeholder="e.g 3324751060"
                    />
                  </div>
                  {fieldErrors?.whatsapp && (
                    <span className="text_error">{fieldErrors?.whatsapp}</span>
                  )}
                </div>
              </div>
              <div ref={wrapperRef} className=" col-md-6 col-12">
                <div className="inp_login">
                  <label>City</label>
                  <input
                    onClick={() => setShow1(true)}
                    value={city_value?.city}
                    onChange={async (e) => {
                      setCityValue({ ...city_value, city: e.target.value });
                      if (e.target.value) {
                        setShow1(true);
                        let array = allcity?.filter((item) =>
                          item?.city
                            ?.toLowerCase()
                            .includes(e.target.value?.toLowerCase())
                        );

                        setcity(array);
                      } else {
                        setShow1(false);
                        setcity(allcity);
                      }
                    }}
                    placeholder="Select Location"
                  />
                  {show1 && (
                    <ul style={{ zIndex: "99" }} className="scroll-box-y">
                      {city?.map((item) => {
                        return (
                          <li
                            onClick={() => {
                              setCityValue({ ...city_value, city: item?.city });
                              setData({ ...data, city_code: item?.app_code });
                              setShow1(false);
                            }}
                          >
                            {item?.city}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {fieldErrors?.city_id && (
                    <span className="text_error">{fieldErrors?.city_id}</span>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="inp_login">
                  <label>Address</label>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      border: "1px solid lightgray",
                      borderRadius: "5px",
                      padding: "0 .5rem",
                    }}
                  >
                    <TbCurrentLocation />
                    <input
                      value={data?.address}
                      onChange={(e) =>
                        setData({ ...data, address: e.target.value })
                      }
                      style={{ border: "none" }}
                      type="text"
                      placeholder="Location Detail, e,g. No. Street"
                    />
                    {fieldErrors?.address && (
                      <span className="text_error">{fieldErrors?.address}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="inp_login ">
              <label className="my-2">Upload a picture</label>
              <div className="propertyimage">
                <div className="image_section py-3 px-3">
                  <div className="row">
                    <div className="col-lg-4 col-md-12 col-12">
                      <div className="d-flex flex-column justify-content-center h-100">
                        <label
                          className="btn1 w-100 mb-lg-0 mb-3"
                          for="upload_image"
                        >
                          Upload Image
                        </label>
                        {/* <button className='btn2 w-100 mt-2'>Image Bank</button> */}
                        <input
                          onChange={handleFileInputChange}
                          hidden
                          type="file"
                          id="upload_image"
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-12">
                      <div
                        style={{
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {data?.image && (
                          <div className="image_main p-0">
                            <img src={data?.image} alt="..." />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {fieldErrors?.image && (
                <span className="text_error">{fieldErrors?.image}</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="agency_setting "

>
  <div className="d-flex justify-content-end">
    <button onClick={submit}>
      {loading ? (
        <div
          className="spinner-border text-light button_loading"
          role="status"
        ></div>
      ) : (
        <>
          Save Changes <LuSave className="ms-1" />
        </>
      )}
    </button>
  </div>
</div>
    </>
  );
}

export default Profile;
