import React, { useState } from "react";
import { FaHome, FaPhoneAlt} from "react-icons/fa";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import LoginModal from "./LoginModal";
import SignUpModal from "./SignUpModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomizedSnackbars from "./CustomizedSnackbars";
import { RiMenu2Line } from "react-icons/ri";
import dummyImage from "../../Images/dummy/profile.webp";
import { IoChevronDownOutline } from "react-icons/io5";
import { useAuth } from "../../Context/ContextProvider";
import { Avatar, Divider, ListItemIcon } from "@mui/material";
import { IoIosArrowUp } from "react-icons/io";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import logo from '../../Images/p.png'
function NavBar() {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("agent_data"));
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open4 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
      setAnchorEl2(event.currentTarget);
  };
  const handleClose4 = () => {
      setAnchorEl2(null);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [open1, setOpen1] = React.useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose1 = () => {
    setOpen1(false);
    setFieldErrors({});
  };
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => {
    setOpen2(false);
    setFieldErrors1({});
  };
  const [down, setDown] = useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const [fieldErrors, setFieldErrors] = React.useState({});
  const [fieldErrors1, setFieldErrors1] = React.useState({});
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const open3 = Boolean(anchorEl1);

  const handleClose3 = () => {
    setAnchorEl1(null);
  };
  const { type, projecttype } = useAuth();
  const [propertyType, setPropertyType] = useState(false);
  const location = useLocation();
  const canonicalUrl = `https://www.pakistanproperty.com${location.pathname}`;
  return (
    <>
            <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      <div className={`${down ? "down" : ""} side_menu`}>
        {/* <ul className="m-0 p-0 px-3">
          <li
            onClick={() => navigate("/listing-property")}
            className="p-0 pe-3"
            style={{ border: "none" }}
          >
            Buy
          </li>
          <li onClick={() => navigate("/listing-property")}>Rent</li>
          <li><div id="basic-button"
            aria-controls={open3 ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open3 ? 'true' : undefined}
          >Projects</div></li>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl1}
            open={open3}
            onClose={handleClose3}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => {
              navigate("/HousingSocity")
              handleClose()
            }}><li className='menue' >Housing Societies</li></MenuItem>
            <MenuItem onClick={() => {
              navigate("/HousingSocity")
              handleClose()
            }}><li className='menue' >High Rise</li></MenuItem>
          </Menu>
          <li onClick={() => navigate("/intstant-valuation")}>
            instant valuation
          </li>
          <li>about us</li>
          <li onClick={() => navigate("/contact")}>contact us</li>
          <li onClick={() => setDown(false)}>
            <button>Close</button>
          </li>
        </ul> */}
        <ul className=" m-0 p-0 px-3">
          {type?.map((item, i) => {
            return (
              <>
                {i === 0 ? (
                  <li
                    onClick={() =>{ setDown(false) 
                      navigate(`/property/property-for-${item?.slug}`)}}
                    className=""
                  >
                    {item?.name === "Sell" ? "Buy" : item?.name}{" "}
                  </li>
                ) : (
                  <li
                    onClick={() =>{ setDown(false)
                       navigate(`/property/property-for-${item?.slug}`)}}
                    className="ms-lg-3 ms-1"
                  >
                    {item?.name === "Sell" ? "Buy" : item?.name}{" "}
                  </li>
                )}
              </>
            );
          })}

          {/* <li><Link className='link_section' style={{ color: "#646C6D" }} to={'/Lease'}>LEASE</Link><Divider className='ms-lg-3 mx-1 mx-lg-0 ' style={{ borderColor: "lightgray", height: "15px", border: "1px solid" }} orientation="vertical" flexItem /></li> */}
          <li className="px-lg-2">
            <div
              onClick={() => setPropertyType((prev) => !prev)} // Toggle propertyType state
            >
              Projects
              {propertyType ? (
                <IoIosArrowUp className="mx-1" />
              ) : (
                <IoChevronDownOutline className="mx-1" />
              )}
            </div>
          </li>
          {propertyType ? (
            <>
              {projecttype?.map((item) => {
                return (
                  <li
                    onClick={() => {
                      navigate(`/project/${item?.id}`);
                      handleClose();
                    }}
                  >
                    <li className="menueNew">{item?.name}</li>
                  </li>
                );
              })}
            </>
          ) : (
            ""
          )}

          <li onClick={() => navigate("/intstant-valuation")}>
            instant valuation{" "}
          </li>
          <li onClick={() => navigate("/agencies")}>agencies </li>
          <li
            onClick={() => navigate("/about-us")}
            style={{ borderRight: "none" }}
          >
            about us
          </li>
          <li onClick={() => setDown(false)}>
            <button>Close</button>
          </li>
        </ul>
      </div>
      <div className="nav_ul d-flex justify-content-between align-items-center py-1">
      <Link to={"/"} className="ms-4">
    <img style={{width:"200px"}} src={logo} alt="..."/>

          </Link>
        <ul className="d-flex justify-content-between m-0 p-0 px-3">
          {type?.map((item, i) => {
            return (
              <>
                {i === 0 ? (
                  <li
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={() => navigate(`/property/property-for-${item?.slug}`)}
                    className=""
                  >
                    {item?.name === "Sell" ? "Buy" : item?.name}{" "}
                    <Divider
                      className="ms-lg-3 mx-1 mx-lg-0 "
                      style={{
                        borderColor: "lightgray",
                        height: "15px",
                        border: "1px solid",
                      }}
                      orientation="vertical"
                      flexItem
                    />
                  </li>
                ) : (
                  <li
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={() => navigate(`/property/property-for-${item?.slug}`)}
                    className="ms-lg-3 ms-1"
                  >
                    {item?.name === "Sell" ? "Buy" : item?.name}{" "}
                    <Divider
                      className="ms-lg-3 mx-1 mx-lg-0 "
                      style={{
                        borderColor: "lightgray",
                        height: "15px",
                        border: "1px solid",
                      }}
                      orientation="vertical"
                      flexItem
                    />
                  </li>
                )}
              </>
            );
          })}

          {/* <li><Link className='link_section' style={{ color: "#646C6D" }} to={'/Lease'}>LEASE</Link><Divider className='ms-lg-3 mx-1 mx-lg-0 ' style={{ borderColor: "lightgray", height: "15px", border: "1px solid" }} orientation="vertical" flexItem /></li> */}
          <li className="px-lg-2">
            <div
              id="basic-button"
              aria-controls={open3 ? "basic-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick1}
              aria-expanded={open3 ? "true" : undefined}
            >
              Projects <IoChevronDownOutline className="mx-1" />
            </div>
            <Divider
              className="ms-lg-3 mx-1 mx-lg-0 "
              style={{
                borderColor: "lightgray",
                height: "15px",
                border: "1px solid",
              }}
              orientation="vertical"
              flexItem
            />
          </li>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl1}
            open={open3}
            onClose={handleClose3}
            style={{marginTop:"1.2rem"}}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {projecttype?.map((item) => {
              return (
                <MenuItem
                  onClick={() => {
                    navigate(`/project/${item?.id}`);
                    handleClose();
                  }}
                >
                  <li style={{textTransform:"capitalize"}} className="menue">{item?.name}</li>
                </MenuItem>
              );
            })}
          </Menu>

          <li onClick={() => navigate("/intstant-valuation")}>
            instant valuation{" "}
            <Divider
              className="ms-lg-3 mx-1 mx-lg-0 "
              style={{
                borderColor: "lightgray",
                height: "15px",
                border: "1px solid",
              }}
              orientation="vertical"
              flexItem
            />
          </li>
          <li onClick={() => navigate("/agencies")}>
            agencies{" "}
            <Divider
              className="ms-lg-3 mx-1 mx-lg-0 "
              style={{
                borderColor: "lightgray",
                height: "15px",
                border: "1px solid",
              }}
              orientation="vertical"
              flexItem
            />
          </li>
          <li
            onClick={() => navigate("/about-us")}
            style={{ borderRight: "none" }}
          >
            about us
          </li>
        </ul>
        <div className="d-flex align-items-center ">
          <div className="nav_btn">

        <button
            onClick={() => {
              if (userData?.data?.token) {
                navigate("/agent/add-property");
              } else {
                handleOpen();
              }
            }}
          >
            Add Property
            <FaHome className="mx-1" />{" "}
          </button>
          </div>
          <div className="d-flex align-items-center">

          <button onClick={() => setDown(true)} className="menu_bar_btn">
          <RiMenu2Line />
        </button>
        <div className="d-flex align-items-center">

          <Divider
            style={{
              borderColor: "lightgray",
              height: "15px",
              border: "1px solid",
              marginTop: "3px",
              marginLeft: "1rem",
            }}
            orientation="vertical"
            flexItem
          />
          {userData?.data?.token ? (
            <>
              {" "}
              <Avatar
                style={{
                  width: "24px",
                  height: "24px",
                  marginLeft: "1rem",
                  marginTop: "-4px",
                  cursor:"pointer",
                  background:"white"
                }}
                alt="Remy Sharp"
                onClick={handleClick2}
                aria-controls={open4 ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open4 ? 'true' : undefined}
                src={userData?.data?.user.avatar}
              />
                     <Menu
                            anchorEl={anchorEl2}
                            id="account-menu"
                            open={open4}
                            onClose={handleClose4}
                            onClick={handleClose4}
                            slotProps={{
                                paper: {
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&::before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={() => {
                                navigate("/agent/user-setting")
                                handleClose4()
                            }}>
                                <Avatar src={userData?.data?.user?.avatar} /> {userData?.data?.user?.name}
                            </MenuItem>

                           {userData?.data?.role==="agency"&& <MenuItem onClick={() => {
                                navigate("/agent/agency-setting")
                                handleClose4()
                            }}>
                                <ListItemIcon>
                                    <PersonAdd fontSize="small" />
                                </ListItemIcon>
                                Agency Setting
                            </MenuItem>}
                            <MenuItem onClick={() => {
                                navigate("/agent/user-setting/setting")
                                handleClose4()
                            }}>
                                <ListItemIcon>
                                    <Settings fontSize="small" />
                                </ListItemIcon>
                                Settings
                            </MenuItem>
                            <MenuItem onClick={() => {
                                localStorage.removeItem("agent_data")
                                // setuserData("")
                                navigate('/')
                                handleClose()
                            }}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
            </>
          ) : (
            <Button
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              style={{ padding: "0" }}
            >
           <Avatar
                style={{
                  width: "24px",
                  height: "24px",
                  marginLeft: "1rem",
                  marginTop: "-4px",
                }}
                alt="Remy Sharp"
                src={dummyImage}
              />
            </Button>
          )}
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem
              onClick={() => {
                handleOpen();
                handleClose();
              }}
              className="menu"
            >
              Sign In
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpen2();
                handleClose();
              }}
              className="menu"
            >
              Registration
            </MenuItem>
          </Menu>
        </div>
          </div>
        </div>

      </div>
      {/* WhatsApp icon */}
      <div className="whatsppDiv d-none d-md-block">

      <a
        href="https://wa.me/+923268099990"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
     
    
      </a>
      <p>
        Talk to us?
      </p>
      </div>

      <LoginModal
        setFieldErrors={setFieldErrors}
        fieldErrors={fieldErrors}
        open={open1}
        handleClose={handleClose1}
        handleOpen={handleOpen2}
      />
      <SignUpModal
        open={open2}
        handleClose={handleClose2}
        handleOpen={handleOpen}
        setFieldErrors={setFieldErrors1}
        fieldErrors={fieldErrors1}
      />
      <CustomizedSnackbars />
    </>
  );
}

export default NavBar;
