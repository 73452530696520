import React, { useState } from 'react'
import { BsFillGrid3X3GapFill, BsShop } from 'react-icons/bs'
import { FaListUl } from 'react-icons/fa'
import { FallbackImage } from '../FallbackImage';
import { Link, useNavigate } from 'react-router-dom';
import img from '../../Images/dummy/placeholder.png'
import img5 from '../../Images/Frame 1000014539.png'
import { IoCameraSharp, IoLocationSharp } from 'react-icons/io5';
import { IoMdCheckmark } from 'react-icons/io';
import img6 from '../../Images/Vector.png'
import img7 from '../../Images/Frame 1000014540.png'
import img9 from '../../Images/Rectangle 748.png'
import Call from '../profile/Call';
import { useAuth } from '../../Context/ContextProvider';
import { CiLocationOn } from 'react-icons/ci';
function ListingProject() {
  const { projectListing, currentCityProject } = useAuth()
  let array = [img]
  const [projectData, setprojectData] = useState()
  function formatPrice(value) {
    if (value >= 10000000) {
      // Convert to Crore
      return (value / 10000000).toFixed(2) + ' Cr';
    } else if (value >= 100000) {
      // Convert to Lakh
      return (value / 100000).toFixed(2) + ' Lakh';
    } else if (value >= 1000) {
      // Convert to Thousand
      return (value / 1000).toFixed(2) + ' Thousand';
    } else {
      // Less than 1000, show as is
      return value?.toString();
    }
  }
  function timeAgo(timestamp) {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const diffInSeconds = Math.floor((now - createdAt) / 1000);
    // Seconds
    if (diffInSeconds < 60) {
      return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago`;
    }
    // Minutes
    if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    }
    // Hours
    if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    }
    // Days
    if (diffInSeconds < 2592000) { // 30 days
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
    // Months
    if (diffInSeconds < 31536000) { // 365 days
      const months = Math.floor(diffInSeconds / 2592000);
      return `${months} month${months > 1 ? 's' : ''} ago`;
    }
    // Years
    const years = Math.floor(diffInSeconds / 31536000);
    return `${years} year${years > 1 ? 's' : ''} ago`;
  }
  const [open1, setOpen1] = useState(false);
  const handleOpen = (item) => {
    setOpen1(true)
    setprojectData(item)
  };
  const navigate=useNavigate()
  const [grid, setGrid] = useState(false)

  return (
    <div className='container project-listing'>
      <div className='row'>

      {
              projectListing?.length===0?
              <div className="col-12">
                 <h5 className='d-flex justify-content-center'>We couldn't find any projects that match your search criteria.</h5>
              </div>
              :
              <>
        <div className='col-6'>
          <h5 className='head_count'>{projectListing?.length} Projects in {currentCityProject?.city_name}</h5>
        </div>
        <div className='col-6 d-flex justify-content-end align-items-center'>
          <FaListUl onClick={() => setGrid(false)} style={{ color: "#2797D4", fontSize: "24px" }} className='mx-2' />
          <BsFillGrid3X3GapFill onClick={() => setGrid(true)} style={{ color: "#2797D4", fontSize: "24px" }} />
           

        </div>
              </>
            }
        
      </div>
      <div className='row'>
        {!grid ? projectListing?.map((item) => {
          return (
            <div style={{ borderRadius: '10px' }} className='least-card  listing p-0 my-2 col-12'>
              <div className='row p-0 m-0'>
                <div className='col-lg-4 p-0'>
                  <Link to={`/project-detail/${item?.id}`} style={{ textDecoration: "none", color: "#313839" }}>
                    <div style={{ position: "relative" }} className='lgos'>
                      <FallbackImage
                        src={item?.images?.length ? item?.images[0]?.url : img}
                        alt="Primary Image"
                        className='list_img' style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: '5px 0 0 5px', }}
                        fallbackSrc={img}
                      />
                      {item?.is_trending ? <div className="position-absolute  pt-1 px-3">
                        {item?.offer?.label === "Super Hot Listing" ? <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
                          <path d="M8 6.83272C8 8.68831 6.43395 10 4.22642 10C1.7217 10 4.76837e-07 8.43237 4.76837e-07 5.85466C4.76837e-07 1.83272 3.64152 0 6.29716 0C6.71698 0 6.92454 0.201087 6.92454 0.434175C6.92454 0.621573 6.82075 0.776952 6.6651 0.991767C6.28774 1.49451 5.64623 2.22577 5.64623 3.14443C5.64623 3.22212 5.65094 3.29983 5.66038 3.38208C5.92453 2.90219 6.39151 2.56398 6.95754 2.56398C7.11793 2.56398 7.20282 2.6554 7.20282 2.78792C7.20282 2.9479 7.1651 3.06216 7.1651 3.56033C7.1651 4.51553 8 5.15996 8 6.83272ZM4.13207 8.71573C5.07546 8.71573 5.70282 8.1627 5.70282 7.34004C5.70282 6.47623 5.07075 6.17002 4.98584 5.61243C4.98113 5.56672 4.94811 5.55301 4.9151 5.58501C4.67925 5.78153 4.53301 6.02833 4.4198 6.31171C4.39624 6.36198 4.3585 6.36654 4.33489 6.32542C4.07547 5.88666 4.0283 5.23309 4.08962 4.41043C4.09906 4.36472 4.06603 4.34186 4.02359 4.36014C2.90566 4.85375 2.33491 5.92779 2.33491 6.89214C2.33491 7.86107 2.92452 8.71573 4.13207 8.71573Z" fill="white" />
                        </svg> :
                          <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12" fill="none">
                            <path d="M7.70351 5.06049C7.70456 5.78468 7.42766 6.50827 6.87537 7.06041C6.58287 7.35336 6.10828 7.35336 5.81533 7.06041C5.52239 6.76747 5.52239 6.29242 5.81533 5.99993C6.36808 5.44763 6.64437 4.72404 6.64347 4C6.64347 2.43807 6.01168 1.02344 4.98629 0C4.98629 1.17284 4.5117 2.23332 3.74311 2.99996L1.74318 4.99989C0.974294 5.76638 0.5 6.82701 0.5 7.99985C0.5 10.208 2.29188 11.9999 4.5 11.9999C6.70902 11.9999 8.5 10.208 8.5 7.99985C8.5 6.85206 8.1958 5.81243 7.70351 5.06049Z" fill="white" />
                          </svg>
                        }
                        Trending
                        <svg className='svg' xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                          <path d="M0 8L8 0H0V8Z" fill="#2797D4" />
                        </svg>
                      </div> : ""}

                      <div className='listing-logos p-2'>

                        <div className='lgs py-1'>
                          {/* <div><FaHeart /></div>
              <div className='align'></div>
              <div><RiShareForwardFill /></div>
              <div className='align'></div> */}
                          <div><IoLocationSharp /></div>
                        </div>
                      </div>
                      <div className='lgs-icon p-2'>

                        <div className='tick px-2'>
                          <span><IoCameraSharp style={{ marginRight: '5px' }} alt='...' />{item?.images?.length}</span>
                        </div>
                        <div className='tick1 px-2'>
                          <span><IoMdCheckmark /><span style={{ marginRight: '7px' }}>Verified</span></span>

                        </div>

                      </div>
                    </div>

                  </Link>
                </div>

                <div className='col-lg-8 '>
                  <div className='py-3 px-2'>
                    <Link to={`/project-detail/${item?.id}`} style={{ textDecoration: "none", color: "#313839" }}>
                      <div className='last-heading'>
                        <h2 style={{ padding: "0", margin: "0" }}>PKR {formatPrice(item?.min_price)} to {formatPrice(item?.max_price)}</h2>
                        <h4 style={{ marginTop: '10px' }}>Added: {timeAgo(item?.created_at)}</h4>
                      </div>
                      <div className='b-heading'>
                        <h2>{item?.project_title}</h2>
                        {/* <span style={{ color: '#2797D4' }}><FaRegHeart /></span> */}
                      </div>
                      {/* <h3>{item?.plot_number + " " + item?.location?.name + " " + item?.city?.name}</h3> */}
                      <h3>{item?.location?.name},{item?.city?.city}</h3>
                      <p>{item?.project_description}

                      </p>
                    </Link>
                    <hr />
                    <div className='rect-img'>
                      <div className='least-btn pb-2'>
                        <a href={`mailto:${item?.email}`}>
                          <button><img src={img5} className='mx-1' alt='...' />Email</button>
                        </a>
                        <button onClick={() => {
                          handleOpen(item)

                        }}><img src={img6} className='mx-1' alt='...' />Call</button>
                        <a href={`https://wa.me/${item?.contacts?.length ? item?.contacts[0] : ""}`} target="_blank" rel="noopener noreferrer">
                          <button><img src={img7} className='mx-1' alt='...' />WhatsApp</button>
                        </a>
                      </div>
                      {item?.is_pakistan_property ? <img src={img9} alt='...' /> : ""}

                    </div>
                  </div>

                </div>
              </div>
            </div>
          )
        })
          : projectListing?.map((item) => {
            return (
              <div className='col-lg-4 col-md-6 col-12  my-3'>
                <div style={{cursor:"pointer"}} onClick={()=>navigate(`/project-detail/${item?.id}`)} className="property-item rounded">
                  <div className="position-relative">
                    <div style={{ width: "100%", overflow: "hidden" }}>
                      <FallbackImage alt={'....'} src={item?.images?.length ? item?.images[0].url : img} fallbackSrc={img} />
                    </div>
                    {/* <a href="..." style={{border:"1px solid"}} className='overflow-hidden'></a> */}
                    <div className="position-absolute  pt-1 px-3"><img src='./image/Frame.svg' alt='...' />Trending</div>
                    <svg className='svg' width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path id="Vector 2" d="M8 8L0 0H8V8Z" fill="#2797D4" />
                    </svg>

                  </div>
                  <div className='p-3'>

                    <h4>{item?.project_title}</h4>
                    <h6> PKR {formatPrice(item?.min_price)} to {formatPrice(item?.min_price)}</h6>
                    <p className='m-0'><CiLocationOn className='me-2' style={{ fontSize: "18px", color: "#2797D4" }} />{item?.location?.name},{item?.city?.city}</p>
                   

                  </div>
                </div>
              </div>
            )
          })}
      </div>
      <Call profileData={{
        user: {
          avatar: projectData?.developer_logo,
          phone_number: projectData?.contact?.length ? projectData?.contact[0] : projectData?.land_line,
          landline: projectData?.land_line,
          email: projectData?.email
        }
      }} open={open1} setOpen={setOpen1} />
    </div>
  )
}

export default ListingProject