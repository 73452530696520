import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Footer from '../Camponent/NavBarFooter/Footer'

function TermsCondition() {
  return (
    <div>
      <NavBar />
      <div className='container-fluid tersm p-sm-4 p-3'>
        <div className='row m-0 '>
          <div className='priv-policy'>

            <h2 style={{ textAlign: 'center' }}>Terms & Conditions</h2>
            <h4>Definitions</h4>
            <h5>By accessing or using our website, you agree to abide by the following terms and conditions. These terms govern your use of the Pakistan Property platform and the services it provides. Please read them carefully, as using our website indicates your acceptance of these terms. If you do not agree with any part of these terms, we kindly ask you to refrain from using our services.</h5>
            <ul>
              <li>Use of the Website</li>
              <p>Pakistan Property provides its content and services strictly for informational purposes. Users are expected to use the website lawfully, respecting the rights of others and the integrity of the platform. Any unauthorized or unlawful use of the website may result in legal action or claims for damages.</p>
              <li>Property Listings</li>
              <p>The platform serves as a medium connecting property owners, agents, and potential buyers. The responsibility for the accuracy, legitimacy, and availability of property listings lies solely with the listing owners or agents. Pakistan Property does not guarantee or endorse the condition or legitimacy of any properties listed on the platform.</p>
              <li> Intellectual Property</li>
              <p>All content available on Pakistan Property, including but not limited to text, images, and graphics, is owned by Pakistan Property unless otherwise stated. The reproduction, distribution, or unauthorized use of this content without prior written consent is strictly prohibited.</p>
              <li>User Accounts</li>
              <p>Users are responsible for maintaining the confidentiality of their account information, including login credentials. Any actions or activities performed through a user’s account will be considered their responsibility. Pakistan Property reserves the right to suspend or terminate accounts found to be in violation of these terms or involved in unauthorized activities.</p>
              <li> Payment and Fees</li>
              <p>Payments for services or features on the platform must be made using the approved payment methods. All fees associated with such services are non-refundable unless explicitly stated otherwise. Users are encouraged to review all charges carefully before making payments.</p>
              <li> Privacy</li>
              <p>By using Pakistan Property, you agree to our Privacy Policy, which outlines how user data is collected, stored, and used. We recommend that all users read our Privacy Policy thoroughly to understand how their personal information is handled on the platform.</p>
              <li> Limitation of Liability</li>
              <p>Pakistan Property will not be held liable for any direct, indirect, or consequential damages arising from the use or inability to use our website or services. We strongly advise users to independently verify the legitimacy and details of any property transactions made through our platform.</p>
              <li> Changes to Terms</li>
              <p>We reserve the right to modify these terms and conditions at any time without prior notice. Your continued use of the website after any changes have been made constitutes your acceptance of the revised terms. Users are encouraged to review these terms periodically.</p>
              <li> Governing Law</li>
              <p>These terms are governed by the laws of Pakistan. Any disputes arising from the use of our platform or services will be resolved under the jurisdiction of Pakistani courts in accordance with applicable laws.</p>
              <li> Contact Us</li>
              <p>If you have any questions or concerns regarding these terms and conditions, you can reach out to us via email at hello@pakistanproperty.com. We’re here to assist and address your inquiries.</p>
            </ul>
            <h5>These definitions are crucial for understanding and interpreting these Terms & Conditions. If you have any questions, please contact us.</h5>
          </div>
        </div>


      </div>
      <Footer />
    </div>
  )
}

export default TermsCondition

