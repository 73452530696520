import React from 'react'
import img from '../../Images/Square-Mall.jpg'
import img1 from '../../Images/Combined-Shape.png'
import img2 from '../../Images/Combined-Shape (1).png'
import { FaFacebookF, FaLinkedinIn, FaPinterest, FaTwitter, FaYoutube } from 'react-icons/fa'
import { BsInstagram } from 'react-icons/bs'
import { FaMagnifyingGlass } from 'react-icons/fa6'
import img5 from '../../Images/Best-Banks-in-Pakistan.jpg'
import img4 from '../../Images/Real-State-Company.jpg'
import img6 from '../../Images/Real-State-Agent.jpg'
import img7 from '../../Images/Square-Mall.jpg'
import { GrInstagram } from 'react-icons/gr'
import { useNavigate } from 'react-router-dom'
import { AiOutlineTikTok, AiOutlineTwitter } from 'react-icons/ai'
function Banner() {
    const navigate = useNavigate()
    return (
        <>
            <div className='container-fluid blog-singal p-0 px-5'>
                <div className='row m-0 px-2'>
                    <div className='col-lg-8 col-12 p-0'>
                        <div className='singal-card'>
                            <div className='singal-img'>
                                <img style={{ width: "100%", height: "400px", objectFit: "cover", borderRadius: "10px" }} src={img} alt='...' />
                            </div>
                            <div className='blog-span'>
                                <span><img src={img1} style={{ width: "15px" }} alt='...' /><h4>Admin</h4></span>
                                <span><img src={img2} style={{ width: "15px" }} alt='...' /><h4>No Comments</h4></span>
                            </div>
                            <h1>Why Madison Square Mall Lahore Is the Top Property Investment of 2025</h1>
                            <p>Lahore's urban landscape is witnessing a transformation, and one development is capturing the spotlight with bold ambition and unmatched potential: <strong>Madison Square Mall</strong>. More than just a mall, this project is setting a new standard for luxury, commerce, and smart investment in Pakistan. If you're looking for the most lucrative real estate opportunity of 2025, here's why <strong>Madison Square Mall</strong> deserves your attention.</p>
                            <h2>Unmatched Location in Lahore's Commercial Core</h2>
                            <p>Located at the prime junction of Mian Mehmood Ali Kasuri Road in Gulberg III, <strong>Madison Square Mall</strong> enjoys unbeatable proximity to Liberty Market, MM Alam Road, and the CBD (Central Business District). It is minutes away from Lahore&rsquo;s busiest shopping zones and residential blocks, making it a dream destination for investors and end-users alike.</p>
                            <p>Whether you're targeting elite shoppers, multinational tenants, or upwardly mobile residents, the location alone guarantees visibility, accessibility, and prestige.</p>
                            <h3>A Signature Mixed-Use Development with Real Returns</h3>
                            <p>What sets <strong>Madison Square Mall</strong> apart is its vertical integration of commercial shops, hotel suites, offices, and lifestyle amenities, all under one iconic high-rise. This mixed use model is not just a trend. It is a proven formula for sustained value, with revenue generated from multiple streams.</p>
                            <p>And with possession ready commercial units and construction nearing completion, this is not a speculative investment. It is a near finished product offering immediate returns.</p>
                            <h3>Verified 15 Percent Guaranteed Rental Yield</h3>
                            <p>Where most real estate investments offer potential returns, <strong>Madison Square Mall</strong> guarantees them. With a 15 percent rental return secured across all units, investors can generate passive income from day one. This rare assurance, backed by a contract, positions Madison Square at the top of Lahore&rsquo;s secure property investments for 2025.</p>
                            <p>Buyers of hotel suites receive even more perks, such as a 30-night free stay voucher annually, blending investment with lifestyle luxury.</p>
                            <h3>Exceptional Amenities Redefining Luxury in Lahore</h3>
                            <p>This is not just a place to shop or stay. It is a full-scale experience. <strong>Madison Square Mall</strong> raises the bar with next-generation features that cater to modern urban lifestyles:</p>
                            <ul>
                                <li>Rooftop infinity pool with skyline views<br />&bull; Luxury hotel suites with AI-powered smart automation<br />&bull; Dedicated valet parking and concierge services<br />&bull; Gourmet dining options, including rooftop restaurants<br />&bull; Premium gym, sauna, spa, and wellness center<br />&bull; High-speed elevators and 24/7 power backup<br />&bull; Multipurpose event and conference halls</li>
                            </ul>
                            <p>These are not just facilities. They are powerful differentiators in Lahore&rsquo;s competitive real estate market.</p>
                            <h3>Delivered by Trusted Developers with Transparent Dealings</h3>
                            <p>Backed by Enaara Developers, known for their credibility and commitment, <strong>Madison Square Mall</strong> is approved by the Lahore Development Authority (LDA), giving investors full peace of mind. Every unit comes with secure documentation, clear legal standing, and an easy-to-understand payment plan.</p>
                            <p>This is not just another flashy brochure. This is a project built on delivery, not just promises.</p>
                            <h3>High Brand Footfall and Long-Term Growth</h3>
                            <p>Several well-known brands have already taken up space in <strong>Madison Square Mall</strong>, ensuring organic footfall and a high visibility index from the start. Whether you own a shop or a suite, this traction will benefit your asset value and occupancy rate.</p>
                            <p>The surrounding infrastructure is also rapidly improving, making it one of the most future-proof areas for business and real estate.</p>
                            <h3>Ideal for Local and Overseas Pakistanis</h3>
                            <p>For overseas investors, <strong>Madison Square Mall</strong> presents a rare chance to own high yield, secure property in Lahore's most dynamic zone. With guaranteed returns, ready possession, and a proven developer, it meets all the criteria of a low risk, high reward venture. And with PakistanProperty.com, investors abroad can handle documentation, unit selection, and updates with complete transparency.</p>
                            <h2>Final Verdict: The Top Investment Pick of 2025</h2>
                            <p>In a crowded market of high rise projects and commercial plazas, <strong>Madison Square Mall</strong> rises above with its unbeatable combination of location, returns, amenities, and credibility. It is not just an investment. It is an entry into a future focused, income generating lifestyle asset.</p>
                            <p>Whether you're looking to lease, resell, or use your unit, you are positioning yourself ahead of the curve by getting involved in Madison Square today.</p>
                            <p>Looking for more verified investment options like <strong>Madison Square Mall</strong>? Head over to<a href="https://www.pakistanproperty.com"> PakistanProperty.com</a> to view premium listings, consult real estate experts, and lock in Lahore&rsquo;s most high-performing properties before the competition catches up.</p>




                            <div className='singal-div'>
                                <h4>Share</h4>
                                <div className='singal-icon'>
                                    <div><FaFacebookF /></div>
                                    <div><FaTwitter /></div>
                                    <div><FaLinkedinIn /></div>
                                    <div><BsInstagram /></div>
                                </div>
                            </div>
                            <h3>Leave a Comments</h3>
                            <div className='singal-input'>
                                <div className='name'>
                                    <h6>Full Name</h6>
                                    <input type='' placeholder='Last Name' />
                                </div>
                                <div className='name'>
                                    <h6>Email</h6>
                                    <input type='' placeholder='arslanmaher702@gmail.com' />
                                </div>
                            </div>
                            <div className='singal-web'>
                                <h6>Email</h6>
                                <input type='' placeholder='Enter Web URL' />
                            </div>
                            <div className='describe-input'>
                                <h6>Describe yourself</h6>
                                <input type='' placeholder='Write here' />
                            </div>
                            <div className='checkbox'>
                                <input type='checkbox' />
                                <h6>Save my name, email, and website in this browser for the next time I comment.</h6>
                            </div>
                            <div className='singal-button'>
                                <button>Post Comment</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-12 px-2'>
                        <div className='singal-side-card p-0'>
                            <div className="input-group serch-card mb-3">
                                <input type="text" className="form-control" placeholder="Serch " aria-label="Serch" aria-describedby="basic-addon2" />
                                <span className="input-group-text" id="basic-addon2"><FaMagnifyingGlass /></span>
                            </div>
                            <div className='Recent-post p-4'>
                                <h3>Recent Post</h3>
                                <div onClick={() => {
                                    navigate("/blog-detail-1")
                                }} className='recent-img '>
                                    <div className='r-img'>
                                        <img src={img4} alt='...' />
                                    </div>
                                    <div className='recent-heading'>
                                        <h5>Top 15 Real Estate Companies in Pakistan for 2025</h5>
                                        <h6>May 02, 2022       3 min read</h6>
                                    </div>
                                </div>
                                <hr />
                                <div onClick={() => {
                                    navigate("/blog-detail-2")
                                }} className='recent-img my-4'>
                                    <div className='r-img'>
                                        <img src={img5} alt='...' />
                                    </div>
                                    <div className='recent-heading'>
                                        <h5>The Top 18 Best Banks in Pakistan for 2025: A Complete Guide</h5>
                                        <h6>May 02, 2022       3 min read</h6>
                                    </div>
                                </div>
                                <hr />
                                <div onClick={() => {
                                    navigate("/blog-detail-3")
                                }} className='recent-img my-4'>
                                    <div className='r-img'>
                                        <img style={{ borderRadius: '10px' }} src={img6} alt='...' />
                                    </div>
                                    <div className='recent-heading'>
                                        <h5>Difference Between Commercial and Residential Real Estate Agent: A Guide</h5>
                                        <h6>May 02, 2022       3 min read</h6>
                                    </div>
                                </div>
                                <hr />
                                <div onClick={() => {
                                    navigate("/blog-detail-4")
                                }} className='recent-img my-4'>
                                    <div className='r-img'>
                                        <img style={{ borderRadius: '10px' }} src={img7} alt='...' />
                                    </div>
                                    <div className='recent-heading'>
                                        <h5>Why Madison Square Mall Lahore Is the Top Property Investment of 2025</h5>
                                        <h6>May 02, 2022       3 min read</h6>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='blog-categories'>
                                <h3>Categories</h3>
                                <div>
                                    <h5>ALL</h5>
                                    <h6>(7)</h6>
                                </div>
                                <div>
                                    <h5>TECHNOLOGY</h5>
                                    <h6>(3)</h6>
                                </div>
                                <div>
                                    <h5>Business</h5>
                                    <h6>(1)</h6>
                                </div>
                                <div style={{ border: 'none' }}>
                                    <h5>Marketing</h5>
                                    <h6>(2)</h6>
                                </div>
                            </div> */}
                            <div className='blog-follow'>
                                <h3>Follow Us</h3>
                                <div className='follow-icon'>
                                    <div>    <a
                                        target="_blank"
                                        style={{color:"white"}}
                                        rel="noreferrer"
                                        href="https://www.facebook.com/profile.php?id=100083395718437"
                                    ><FaFacebookF />  </a></div>
                                    <div>  <a
                                        target="_blank"
                                        style={{color:"white"}}
                                        rel="noreferrer"
                                        href="https://twitter.com/Pak_Pro_"
                                    >
                                        <AiOutlineTwitter />{" "}
                                    </a></div>
                                    <div>  <a
                                        target="_blank"
                                        style={{color:"white"}}
                                        rel="noreferrer"
                                        href="https://www.linkedin.com/company/pakistanproperty/"
                                    >
                                        <FaLinkedinIn />{" "}
                                    </a></div>
                                    <div><a
                                        target="_blank"
                                        style={{color:"white"}}
                                        rel="noreferrer"
                                        href="https://www.tiktok.com/@pakistanproperty.com"
                                    >
                                        <AiOutlineTikTok />{" "}
                                    </a></div>
                                    <div> <a
                                        target="_blank"
                                        style={{color:"white"}}
                                        rel="noreferrer"
                                        href="https://www.youtube.com/@PakistanPropertyOfficial"
                                    >
                                        <FaYoutube />{" "}
                                    </a></div>
                                    <div> <a
                                        target="_blank"
                                        style={{color:"white"}}
                                        rel="noreferrer"
                                        href="https://www.pinterest.com/pakistanpropertyofficial/"
                                    >
                                        <FaPinterest />{" "}
                                    </a></div>
                                </div>
                            </div>
                            <div className='view-button'>
                                <button onClick={() => navigate('/blogs')}>View All Blogs</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner
