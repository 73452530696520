import React from 'react'

function AdInformation({ data, setData, fieldErrors }) {
    return (
        <div className='adInfo locationPurpos feature_amenities my-sm-3 my-3'>
            <div className='d-flex align-items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="23" viewBox="0 0 21 23" fill="none">
                    <path d="M13.4741 22.9999H2.49159C1.11638 22.998 0.00196266 21.8834 0 20.5083V2.49159C0.00191654 1.11638 1.11646 0.00196266 2.49159 0H15.4405C16.8157 0.00191654 17.9301 1.11646 17.9321 2.49159V11.6916C17.9321 12.0088 17.6743 12.2666 17.3571 12.2666C17.0398 12.2666 16.7821 12.0088 16.7821 11.6916V2.49159C16.7821 2.13606 16.6412 1.7949 16.3891 1.54285C16.1371 1.29082 15.7959 1.14992 15.4404 1.14992H2.4915C1.75072 1.14992 1.14982 1.75079 1.14982 2.49159V20.5083C1.14982 21.2491 1.75069 21.85 2.4915 21.85H13.474C13.7913 21.85 14.0491 22.1078 14.0491 22.425C14.0491 22.7422 13.7914 22.9999 13.4741 22.9999Z" fill="#B7B7B7" />
                    <path d="M13.7578 4.63049H4.17462C3.8574 4.63049 3.59961 4.37269 3.59961 4.05548C3.59961 3.73826 3.8574 3.48047 4.17462 3.48047H13.7578C14.075 3.48047 14.3328 3.73826 14.3328 4.05548C14.3328 4.37269 14.075 4.63049 13.7578 4.63049Z" fill="#B7B7B7" />
                    <path d="M9.552 7.58215H4.18536C3.86815 7.58215 3.61035 7.32435 3.61035 7.00714C3.61035 6.68992 3.86815 6.43213 4.18536 6.43213H9.552C9.86922 6.43213 10.127 6.68992 10.127 7.00714C10.127 7.32435 9.86922 7.58215 9.552 7.58215Z" fill="#B7B7B7" />
                    <path d="M13.7578 10.5304H4.17462C3.8574 10.5304 3.59961 10.2726 3.59961 9.95538C3.59961 9.63817 3.8574 9.38037 4.17462 9.38037H13.7578C14.075 9.38037 14.3328 9.63817 14.3328 9.95538C14.3328 10.2726 14.075 10.5304 13.7578 10.5304Z" fill="#B7B7B7" />
                    <path d="M9.552 13.4777H4.18536C3.86815 13.4777 3.61035 13.2199 3.61035 12.9026C3.61035 12.5854 3.86815 12.3276 4.18536 12.3276H9.552C9.86922 12.3276 10.127 12.5854 10.127 12.9026C10.127 13.2199 9.86922 13.4777 9.552 13.4777Z" fill="#B7B7B7" />
                    <path d="M16.6519 14.4438C15.9726 14.4438 15.3086 14.6453 14.7437 15.0227C14.1789 15.4001 13.7387 15.9365 13.4787 16.5641C13.2188 17.1917 13.1508 17.8823 13.2833 18.5486C13.4158 19.2148 13.7429 19.8268 14.2233 20.3072C14.7036 20.7875 15.3156 21.1146 15.9819 21.2472C16.6481 21.3797 17.3387 21.3117 17.9663 21.0517C18.5939 20.7917 19.1303 20.3515 19.5077 19.7867C19.8852 19.2219 20.0866 18.5578 20.0866 17.8785C20.0866 16.9676 19.7247 16.094 19.0806 15.4498C18.4365 14.8057 17.5629 14.4438 16.6519 14.4438ZM17.1426 19.8412C17.1426 19.9713 17.0909 20.0961 16.9989 20.1881C16.9069 20.2801 16.7821 20.3318 16.6519 20.3318C16.5218 20.3318 16.397 20.2801 16.305 20.1881C16.213 20.0961 16.1613 19.9713 16.1613 19.8412V17.3878C16.1613 17.2577 16.213 17.1329 16.305 17.0409C16.397 16.9489 16.5218 16.8972 16.6519 16.8972C16.7821 16.8972 16.9069 16.9489 16.9989 17.0409C17.0909 17.1329 17.1426 17.2577 17.1426 17.3878V19.8412ZM16.6519 16.6518C16.5549 16.6518 16.46 16.6231 16.3793 16.5691C16.2987 16.5152 16.2358 16.4386 16.1986 16.3489C16.1615 16.2593 16.1518 16.1606 16.1707 16.0654C16.1896 15.9703 16.2364 15.8828 16.305 15.8142C16.3736 15.7456 16.461 15.6989 16.5562 15.6799C16.6514 15.661 16.7501 15.6707 16.8397 15.7079C16.9294 15.745 17.006 15.8079 17.0599 15.8886C17.1138 15.9693 17.1426 16.0641 17.1426 16.1612C17.1426 16.2913 17.0909 16.4161 16.9989 16.5081C16.9069 16.6001 16.7821 16.6518 16.6519 16.6518Z" fill="#2797D4" />
                </svg>

                <h4 className='new_heading'>Ad Information</h4>
            </div>
            <div className="row">
                <div className="col-md-10 col-12">
                    <div className='inp_login'>
                        <label className="logo_label">Title</label>
                        <input value={data?.ad_title} onChange={(e) => setData({ ...data, ad_title: e.target.value })} type='text' placeholder='Enter Unit' />
                        {fieldErrors?.ad_title && <span className='text_error'>{fieldErrors?.ad_title}</span>}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-10 col-12">
                    <div className='inp_login'>
                        <label className="logo_label">Description</label>
                        <textarea value={data?.ad_description} onChange={(e) => setData({ ...data, ad_description: e.target.value })} type='text' placeholder='Describe your property, its features, area it is in etc.' />
                            
                        {fieldErrors?.ad_description && <span className='text_error' style={{ top: "100%" }}>{fieldErrors?.ad_description}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdInformation
