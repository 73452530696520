import React, { useEffect, useRef, useState } from "react";
import img from "../../Images/dummy/placeholder.png";
import img_2 from "../../Images/dummy/images.jfif";
import whatsapp from "../../Images/listing/whatsapp.png";
import { IoIosCall, IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { teal } from "@mui/material/colors";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineBed } from "react-icons/md";
import { LuBath } from "react-icons/lu";
import Card from "./Card";
import flag from "../../Images/listing/pak.png";
import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../Context/ContextProvider";
import Call from "./Call";
import square from "../../Images/Square Meters.png";
import { FallbackImage } from "../FallbackImage";
import imgAmin from "../../Images/HousingSoceityDetaislIcons/Group 1000014147 (2).png";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
function Banner({ profileData }) {
  const { properyEnquery, whatsappImpretion ,loading} = useAuth();
  const [open, setOpen] = useState(false);
  const handleChange = (event) => {
    setData({ ...data, type: event.target.value });
  };
  const [open1, setOpen1] = useState(false);

  const handleOpen = () => setOpen1(true);
  const controlProps = (item) => ({
    checked: data.type === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  const parms = useParams();
  const [fieldErrors, setFieldErrors] = React.useState({});
  const [data, setData] = useState({
    email: "",
    name: "",
    contact_number: "",
    message: "",
    property_id: parms?.id,
    type: "buyer/tenant",
    keep_informed: "",
  });
  const validateStep = () => {
    let errors = {};
    if (!data.name) {
      errors.name = "Name is required.";
    }
    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (!data.message) {
      errors.message = "Message is required.";
    }

    if (!data.type) {
      errors.type = "Type is required.";
    }

    if (!data.contact_number) {
      errors.contact = "Contact is required.";
    } else if (!isValidPakistaniPhoneNumber(data.contact_number)) {
      errors.contact = "Please enter a valid  phone number.";
    }
    setFieldErrors(errors);

    return Object.keys(errors).length === 0;
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const formatPakistaniPhoneNumber = (phoneNumber) => {
    let phone = phoneNumber.replace(/\D/g, ""); // Remove non-digit characters

    // Handle different input formats
    if (phone.startsWith("92")) {
      phone = phone.slice(2);
    } else if (phone.startsWith("+92")) {
      phone = phone.slice(3);
    } else if (phone.startsWith("0")) {
      phone = phone.slice(1);
    }

    // Ensure the phone number is no longer than 10 digits
    if (phone.length > 10) {
      phone = phone.slice(0, 10);
    }
    return phone; // Return processed phone number
  };
  const isValidPakistaniPhoneNumber = (phoneNumber) => {
    let cleanedPhoneNumber = phoneNumber.replace(/[^\d\+]/g, "");

    const pakistaniPhoneRegex = /^(?:0|\+92)?\s?(?:\d\s?){10}$/;

    const isValid = pakistaniPhoneRegex.test(cleanedPhoneNumber);

    return isValid;
  };
  const submit = async (event) => {
    event.preventDefault();
    if (validateStep()) {
      let result = await properyEnquery({
      
        ...data,
        contact_number: "+92" + data?.contact_number,
      });
      
      if (result?.success) {
        setData({
          email: "",
          name: "",
          contact_number: "",
          message: "",
          property_id: parms?.id,
          type: "buyer/tenant",
          keep_informed: "",
        });
      }
    }
  };

  function formatPrice(value) {
    if (value >= 10000000) {
      // Convert to Crore
      return (value / 10000000).toFixed(2) + " Crore";
    } else if (value >= 100000) {
      return (value / 100000).toFixed(2) + " Lakh";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(2) + " Thousand";
    } else {
      return value.toString();
    }
  }


  function timeAgo(timestamp) {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const diffInSeconds = Math.floor((now - createdAt) / 1000);
    // Seconds
    if (diffInSeconds < 60) {
      return `${diffInSeconds} second${diffInSeconds > 1 ? "s" : ""} ago`;
    }
    // Minutes
    if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    }
    // Hours
    if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    }
    // Days
    if (diffInSeconds < 2592000) {
      // 30 days
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days > 1 ? "s" : ""} ago`;
    }
    if (diffInSeconds < 31536000) {
      // 365 days
      const months = Math.floor(diffInSeconds / 2592000);
      return `${months} month${months > 1 ? "s" : ""} ago`;
    }
    // Years
    const years = Math.floor(diffInSeconds / 31536000);
    return `${years} year${years > 1 ? "s" : ""} ago`;
  }

  const boxRef = useRef(null);
    const [showFullText, setShowFullText] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const location=useLocation()
  useEffect(() => {
    if (boxRef.current) {
      
      const lineHeight = parseInt(
        getComputedStyle(boxRef.current).lineHeight,
        10
      );
      const scrollHeight = boxRef.current.scrollHeight;
      const maxVisibleHeight = lineHeight * 3; // Max height for 3 lines
      console.log("hello",scrollHeight,maxVisibleHeight);
      
      // Set isTruncated only if the content exceeds 3 lines
      if (scrollHeight > maxVisibleHeight) {
        setIsTruncated(true);
      } else {
        setIsTruncated(false);
      }

    }
  }, [loading]);



  // const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // const images = profileData?.images?.length >= 1 ? profileData.images : [img];

  // useEffect(() => {
  //   if (images.length > 1) {
  //     const interval = setInterval(() => {
  //       setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  //     }, 3000);

  //     return () => clearInterval(interval);
  //   }
  // }, [images]);


  return (
    <>
  {loading?<div className="container-fluid mt-4 px-3">
                <div className="row px-4">
                
                    <div className="col-lg-8 col-12 p-0">
                    <div className="col-12 my-2" >
              <span
                className="placeholder"
                style={{ width: "100%", height: "300px", borderRadius: "5px" }}
              ></span>
             
            </div>
            {[...Array(4)].map((_, rowIndex) => (
                <span
                  key={rowIndex}
                  className="placeholder"
                  style={{
                    width: "100%",
                    height: "50px",
                    marginTop: "15px",
                    borderRadius: "5px",
                  }}
                ></span>
              ))}
  
                    </div>
                    <div className="col-lg-4 col-12 my-lg-0 mt-5 mb-4 new_special_col" style={{ paddingLeft: "26px" }}>
                   
            <div className="col-12 my-2">
                <div className="d-flex justify-content-center align-items-center">
              <span
                className="placeholder"
                style={{ height: "100px",width:"100px", borderRadius: "50%" }}
              ></span>
                </div>
              {[...Array(4)].map((_, rowIndex) => (
                <span
                  key={rowIndex}
                  className="placeholder"
                  style={{
                    width: "100%",
                    height: "50px",
                    marginTop: "15px",
                    borderRadius: "5px",
                  }}
                ></span>
              ))}
            </div>
                  

                    </div>
                </div>

            </div>:  <div className="row  my-3">
      <div className="col-lg-8 col-12  my-2 listing_main">
      
        <div
          onClick={() => setOpen(true)}
          style={{
            cursor: "pointer",
            borderRadius: "5px",
            overflow: "hidden",
            position: "relative",
          }}
          className="listing_main_img"
        >
          <div className="sideArrow">
                          <div className="d-flex justify-content-between align-items-center h-100 px-2 ">
                          <MdKeyboardArrowLeft />
                          <MdKeyboardArrowRight />
                          </div>
                        </div>
          {/* <FallbackImage
            src={images[currentImageIndex]?.url || img}
            alt="primry img"
            fallbackSrc={img}
          /> */}
           <img   src={ profileData?.images?.length? profileData?.images[0].url:img} alt="..." />
          <div
            style={{
              position: "absolute",
              zIndex: "99",
              background: "white",
              top: "0",
              color: "#2797D4",
              padding: "4px 9px",
              display: "flex",
              width: "80px",
              height: "22px",
              margin: "1rem",
              borderRadius: "3px",
            }}
            className="img_video_gellery_main justify-content-between align-items-center"
          >
            <div
              className="justify-content-center align-items-center"
              style={{
                gap: "3px",
                padding: "0",
                margin: "0",
                display: "flex",
                fontSize: "10px",
              }}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.85742 1.28571H8.89314L8.57171 0.642857C8.38239 0.269036 8.28371 0 7.92885 0H4.07171C3.71685 0 3.59921 0.306643 3.42885 0.642857L3.10742 1.28571H2.14314C1.4331 1.28571 0.857422 1.86139 0.857422 2.57143V7.71429C0.857422 8.42432 1.4331 9 2.14314 9H9.85742C10.5675 9 11.1431 8.42432 11.1431 7.71429V2.57143C11.1431 1.86139 10.5675 1.28571 9.85742 1.28571ZM6.00028 7.71429C4.58021 7.71429 3.42885 6.56293 3.42885 5.14286C3.42885 3.72279 4.58021 2.57143 6.00028 2.57143C7.42035 2.57143 8.57171 3.72279 8.57171 5.14286C8.57171 6.56293 7.42035 7.71429 6.00028 7.71429ZM6.00028 3.21429C4.93539 3.21429 4.07171 4.07796 4.07171 5.14286C4.07171 6.20775 4.93539 7.07143 6.00028 7.07143C7.06517 7.07143 7.92885 6.20775 7.92885 5.14286C7.92885 4.07796 7.06517 3.21429 6.00028 3.21429Z"
                    fill="#2797D4"
                  />
                </svg>
              </span>
              <span>{profileData?.images?.length}</span>
            </div>
            <div
              className="h-100"
              style={{ borderRight: "1px solid #2797D4" }}
            ></div>
            <div
              style={{
                display: "flex",
                fontSize: "10px",
                color: "#2797D4",
                gap: "3px",
              }}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="9"
                  viewBox="0 0 14 9"
                  fill="none"
                >
                  <path
                    d="M9.04167 2.88504L13.034 0.598607C13.5625 0.290304 14 0.508032 14 1.14322V7.89278C14 8.50997 13.5625 8.76369 13.034 8.45539L9.04167 6.15096V7.85678C9.04167 8.49197 8.53125 9 7.89308 9H1.11183C0.49175 9 0 8.49197 0 7.85678V1.12522C0 0.490033 0.492333 0 1.11183 0H7.89308C8.53125 0 9.04167 0.490033 9.04167 1.12522V2.88504Z"
                    fill="#2797D4"
                  />
                </svg>
              </span>
              <span>{profileData?.video_links?.length}</span>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              zIndex: "99",
              background: "white",
              top: "0",
              display: "flex",
              margin: "1rem",
              marginLeft: "115px",
              borderRadius: "3px",
              width: "64px",
              height: "22px",
            }}
            className="img_video_gellery_main"
          >
            <div
              className="justify-content-center align-items-center w-100"
              style={{
                display: "flex",
                gap: "6px",
                fontSize: "11px",
                color: "#2797D4",
              }}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="9"
                  viewBox="0 0 11 9"
                  fill="none"
                >
                  <path
                    d="M1.24219 4.39945L4.02861 7.37401L10 1"
                    stroke="#2797D4"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span>Verified</span>
            </div>
          </div>
          
        </div>
        <div className="listing_main new_listing_main px-3 pt-3 pb-4">
          <h1 title={profileData?.title} className="m-0">{profileData?.title} </h1>
          <h2 className="p-0 mb-2" style={{lineHeight:'10px'}}>
            <span className="m-0">
             {profileData?.plot_number} {profileData?.location?.name} {profileData?.city?.name}
            </span>
          </h2>
          <h2>
            {profileData?.currency}{" "}
            {formatPrice(profileData?.price ? profileData?.price : 0)}{" "}
            {profileData?.property_type?.slug === "rent" ? "/ Month" : ""}{" "}
            <span className="d-sm-inline d-none">
              Added:&nbsp;{timeAgo(profileData?.created_at)}
            </span>
          </h2>
          <h2>
            <span className="d-sm-none d-block">
              Added:&nbsp; {timeAgo(profileData?.created_at)}
            </span>
          </h2>
         
          <div className="scroll-box mb-sm-0 mb-3">
            <div className="d-flex">
              <div
                className="d-flex property_small new_property_small flex-wrap"
                style={{ overflow: "hidden" }}
              >
                <small className="text-center py-2 d-flex justify-content-center align-items-center">
                  <img src={square} alt="" />
                  {profileData?.area_size} {profileData?.unit_area}
                </small>

                {profileData?.bedrooms &&
                  profileData?.bedrooms !== "undefined" ? (
                  <span>
                    <small
                      style={{ textTransform: "capitalize" }}
                      className="flex-fill text-center py-2 d-flex justify-content-center align-items-center"
                    >
                      <MdOutlineBed
                        style={{
                          color: "#2797D4",
                          fontSize: "20px",
                          margin: "0",
                        }}
                      />
                      {profileData?.bedrooms}{" "}
                      {profileData?.bedrooms !== "studio" && "Beds"}
                    </small>
                  </span>
                ) : (
                  ""
                )}

                {profileData?.bathrooms &&
                  profileData?.bathrooms !== "undefined" ? (
                  <span>
                    <small className="flex-fill text-center py-2 d-flex justify-content-center align-items-center">
                      <LuBath
                        style={{
                          color: "#2797D4",
                          fontSize: "20px",
                          margin: "0",
                        }}
                      />
                      {profileData?.bathrooms} Bathroom
                    </small>
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {profileData?.property_type?.slug === "lease" && (
            <div className="listing_main">
              <div className="property-type">
                <div className="p">
                  <h4>Lease Duration</h4>
                  <h3 style={{ display: "flex", marginTop: '12px' }}>
                    {profileData?.lease?.duration}
                    <p
                      style={{
                        textTransform: "capitalize",
                        marginLeft: ".5rem",
                        marginBottom: '0'
                      }}
                    >
                      {profileData?.lease?.duration_type}
                    </p>
                  </h3>
                </div>
                <div className="p">
                  <h4>Available Space</h4>
                  <h3 style={{ display: "flex", marginTop: '12px' }}>
                    {profileData?.area_size}
                    <p
                      style={{
                        textTransform: "capitalize",
                        marginLeft: ".5rem",
                        marginBottom: '0'
                      }}
                    >
                      {profileData?.unit_area}
                    </p>
                  </h3>
                </div>
                <div className="p">
                  <h4>Building Size</h4>
                  <h3 style={{ display: "flex", marginTop: '12px' }}>
                    {profileData?.lease?.building_area_size}
                    <p
                      style={{
                        textTransform: "capitalize",
                        marginLeft: ".5rem",
                        marginBottom: '0'
                      }}
                    >
                      {profileData?.lease?.building_unit_area}
                    </p>
                  </h3>
                </div>
                <div className="p">
                  <h4>Lease Type</h4>
                  <h3 style={{ textTransform: "capitalize", marginTop: '12px' }}>
                    {profileData?.lease?.type}
                  </h3>
                </div>
                <div style={{ border: "none" }} className="p">
                  <h4>Availability</h4>
                  <h3 style={{ textTransform: "capitalize", marginTop: '12px' }}>
                    {profileData?.lease?.availability}
                  </h3>
                </div>
              </div>
            </div>
          )}
          <h1 className="discrip_head">Description</h1>
          {/*  <p className="discription">
                        {!showFullText && profileData?.description?.length > 240
                            ? `${profileData?.description.slice(0, 640)}...`
                            : profileData?.description}
                    </p>
                    {profileData?.description?.length > 240 && <div className="d-flex justify-content-end">
                        <button onClick={() => setShowFullText(!showFullText)}
                            className="read_more"
                        >
                            {showFullText ? (
                                <>
                                    Read Less <FaCaretUp className="mx-2" />
                                </>
                            ) : (
                                <>
                                    Read More <FaCaretDown className="mx-2" />
                                </>
                            )}</button>
                    </div>} */}

          <p
            ref={boxRef}
            className={`discription ${showFullText ? "show-full-text" : "clamp-text"
              }`}
            style={{
              maxHeight: showFullText ? "100%" : "60px", // Adjust for toggling
              overflow: showFullText ? "visible" : "hidden", // Prevent clipping when expanded
              lineHeight: "20px", // Ensure consistent line height
              whiteSpace: "normal",
            }}
          >
            {profileData?.description}
            {/* Ashiana Housing Scheme at Lahore is a Project of Punjab government managed by Punjab Land Development Company (PLDC). Construction of 3 Marla 500 houses having covered area 800 SFT each is an honor for DCC. This project has prime national importance to meet the growing housing demand in Pakistan. */}
          </p>
          {isTruncated  && (
            <div className="read-button d-flex justify-content-end my-sm-3 my-1">
              {isTruncated && (
                <button
                  className="slot-read-function px-4"
                  onClick={() => setShowFullText(!showFullText)}
                >
                  {showFullText ? (
                    <>
                      Read Less
                      <IoMdArrowDropup />
                    </>
                  ) : (
                    <>
                      Read More
                      <IoMdArrowDropdown />
                    </>
                  )}
                </button>
              )}
            </div>
          )}

          <hr className="new_hr_line" />
          <div className="aminty">
            <h1 className="discrip_head">Amenities</h1>
            {profileData?.amenities?.length ? (
              profileData?.amenities?.map((item) => {
                return (
                  <div className="mian_feature">
                    <h3 className="discrip_subhead">{item?.category_name}</h3>
                    <div className="row">
                      {item?.data?.map((items) => {
                        return (
                          <div className="col-lg-3 col-sm-6 col-12 my-1 d-flex align-items-start">
                            <FallbackImage
                              src={items?.image}
                              alt={"...."}
                              className={"me-1"}
                              style={{ width: "15px", marginTop: "4px" }}
                              fallbackSrc={imgAmin}
                            />

                            <span>
                              {items?.label}
                              {items?.type === "select" ||
                                items?.type === "text"
                                ? `: ${items?.selected_options}`
                                : ""}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="mian_feature">
                <h3 className="discrip_subhead">
                  Amenities not found in this Property !
                </h3>
              </div>
            )}
          </div>
          <hr className="new_hr_line my-3" />
          {/* <div className="d-flex justify-content-end">
                        <button className="read_more">Read More <FaCaretDown className="mx-2" />  </button>
                    </div> */}
 <h1 className="discrip_head">Location</h1>
          <iframe
            className="profile_map"
            title="map"
            src={`https://maps.google.com/maps?q=${profileData?.location?.latitude},${profileData?.location?.longitude}&z=15&output=embed`}
            width="100%"
            height="450"
            style={{ border: "0", borderRadius: "5px" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          {/* <hr /> */}
          {/* <h1>Home Finance</h1>
                    <div className="house_finance">
                        <p>Calculate and view the monthly mortgage on this house</p>
                        <div className="d-flex align-items-center">
                            <h5 className="me-2">Conventional</h5>
                            <img src={logo} alt="..." />
                        </div>
                        <div className="row">
                            <div className="col-lg-5 col-md-6 col-12">
                                <div className='inp_login'>
                                    <label className="logo_label">Interest Plan</label>
                                    <select onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='Email' >
                                        <option>HBFC Khaas</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="payment_card">
                                    <h1 className="m-0">PKR 3.82 Lakh</h1>
                                    <h6 className="m-0">Monthly Payment</h6>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="payment_card">
                                    <h1 className="m-0">PKR 3.82 Crore</h1>
                                    <h6 className="m-0">Bank Finance Amount</h6>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-12">
                                <div className="payment_card">
                                    <div className="d-flex justify-content-center">
                                        <h6 className="mx-2 my-0">Interest</h6>
                                        <h6 className="mx-2 my-0">Principal</h6>
                                    </div>
                                    <div className="bar_main">
                                        <div className="bar"></div>
                                    </div>
                                    <h6 className="my-0">Bank Finance Amount</h6>
                                </div>
                            </div>
                        </div>
                        <div className="row my-2">
                            <div className="col-lg-6 col-12">
                                <div className='inp_login pt-0'>
                                    <label className="logo_label m-0">Property Price</label>
                                    <input onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='38,500,000 PKR' />
                                    <PrettoSlider
                                        style={{ marginTop: "1rem" }}
                                        valueLabelDisplay="auto"
                                        aria-label="pretto slider"
                                        defaultValue={20}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className='inp_login pt-0'>
                                    <label className="logo_label m-0">Loan Period</label>
                                    <input onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='25 Years' />
                                    <PrettoSlider
                                        style={{ marginTop: "1rem" }}
                                        valueLabelDisplay="auto"
                                        aria-label="pretto slider"
                                        defaultValue={20}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className='inp_login pt-0'>
                                    <label className="logo_label m-0">Interest Rate</label>
                                    <input onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='Email' />
                                    <PrettoSlider
                                        style={{ marginTop: "1rem" }}
                                        valueLabelDisplay="auto"
                                        aria-label="pretto slider"
                                        defaultValue={20}
                                    />

                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className='inp_login'>
                                    <label className="logo_label m-0">Interest Rate</label>
                                    <input onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='Email' />
                                    <PrettoSlider
                                        style={{ marginTop: "1rem" }}
                                        valueLabelDisplay="auto"
                                        aria-label="pretto slider"
                                        defaultValue={20}
                                    />
                                </div>
                            </div>

                        </div>
                        <p><span>DISCLAIMER:</span> Interest rates may vary from the  value shown on Zameen.com. Actual interest rate prevalent as per bank’s  policy will be applicable at the time of application.</p>
                        <button className="apply_btn">Apply For Loan</button>
                    </div> */}
        </div>
      </div>
      <div className="col-lg-4 col-12 my-2 pe-2">
        <div className="logo_prop px-3 pt-2 pb-0">
          <div className="logo_info mt-1">
            <div className="logo_img profileImg">
              <img
                src={
                  profileData?.user?.avatar ? profileData?.user?.avatar : img_2
                }
                alt=""
              />
            </div>

            <p className="heading_info"> {profileData?.user?.name}</p>
            <p
              style={{ color: "#646C6D", fontSize: "14px" }}
              className="content_info"
            >
              {profileData?.user?.email}
              <br />
              Property ID: {profileData?.property_number}
            </p>
            <div className="info_btns">
              <button
                onClick={() => {
                  handleOpen();
                }}
              >
                <IoIosCall className="me-2" />
                Call
              </button>

              <a
                onClick={() => whatsappImpretion("whatsapp", parms?.id)}
                href={`https://wa.me/${profileData?.user?.whatsapp_number}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>
                  <img src={whatsapp} alt="" className="me-2" />
                  WhatsApp
                </button>
              </a>
            </div>
          </div>
          <form onSubmit={submit}>
            <div className="inp_login mb-3 p">
              <label className="logo_label">Name</label>
              <input
                onChange={(e) => setData({ ...data, name: e.target.value })}
                value={data?.name}
                type="text"
                placeholder="Name"
              />
              {fieldErrors?.name && (
                <span className="text_error">{fieldErrors?.name}</span>
              )}{" "}
            </div>
            <div className="inp_login mt-2">
              <label className="logo_label mt-2">Email</label>
              <input
                onChange={(e) => setData({ ...data, email: e.target.value })}
                type="email"
                value={data?.email}

                placeholder="Email"
              />
              {fieldErrors?.email && (
                <span className="text_error">{fieldErrors?.email}</span>
              )}
            </div>

            <div className="inp_login">
              <label className="logo_label">Contact</label>
              <div className="contact_contry">
                <div className="img_container pakImg">
                  <img src={flag} alt="..." />
                  <span>+92</span>
                </div>
                <input
                  value={data?.contact_number}
                  style={{ borderRadius: " 0 5px 5px 0 ", borderLeft: "none" }}
                  onChange={(e) =>
                    setData({
                      ...data,
                      contact_number: formatPakistaniPhoneNumber(
                        e.target.value
                      ),
                    })
                  }
                  type="number"
                  placeholder="e.g 3324751060"
                />
              </div>
              {fieldErrors?.contact && (
                <span className="text_error">{fieldErrors?.contact}</span>
              )}
            </div>
            <div className="inp_login mb-0">
              <label className="logo_label">Message</label>
              <textarea
                value={data?.message}

                style={{
                  minHeight: "100px",
                  width: "100%",
                  border: "1px solid lightgray",
                  borderRadius: "5px",
                  padding: ".5rem",
                }}
                onChange={(e) => setData({ ...data, message: e.target.value })}
                type="email"
                placeholder="Write your message....."
              />
              {fieldErrors?.message && (
                <span className="text_error" style={{ top: "97%" }}>
                  {fieldErrors?.message}
                </span>
              )}
            </div>
            <div className="form_radio d-flex ">
              <label className="logo_label" style={{ whiteSpace: "nowrap" }}>
                I am a:
              </label>
              <div className="d-flex flex-wrap align-items-center mt-2">
                <div style={{ whiteSpace: "nowrap" }}>
                  <Radio
                    {...controlProps("buyer/tenant")}
                    checked={data?.type === "buyer/tenant" ? true : false}
                    sx={{
                      color: teal[800],
                      "&.Mui-checked": {
                        color: teal[600],
                      },
                    }}
                  />
                  <span style={{ color: "#646C6D" }}>Buyer / Tenant</span>
                </div>
                <div style={{ whiteSpace: "nowrap" }}>
                  <Radio
                    {...controlProps("agent")}
                    checked={data?.type === "agent" ? true : false}
                    sx={{
                      color: teal[800],
                      "&.Mui-checked": {
                        color: teal[600],
                      },
                    }}
                  />
                  <span style={{ color: "#646C6D" }}>Agent</span>
                </div>
                <div style={{ whiteSpace: "nowrap" }}>
                  <Radio
                    {...controlProps("other")}
                    checked={data?.type === "other" ? true : false}
                    sx={{
                      color: teal[800],
                      "&.Mui-checked": {
                        color: teal[600],
                      },
                    }}
                  />
                  <span style={{ color: "#646C6D" }}>Other</span>
                </div>
                {fieldErrors?.type && (
                  <span className="text_error">{fieldErrors?.type}</span>
                )}
              </div>
              {/* <div className="inp_login">
                                <input style={{ borderRadius: "5px", color: 'black' }} type='text' value='Invester' readOnly />
                            </div> */}
            </div>
            {/* <div className='inp_login px-2'>
                            <input onChange={(e) => setData({ ...data, name: e.target.value })} type='text' placeholder='Investor' />
                            {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                        </div> */}
            <div className="form_radio d-flex align-items-center">
              <Checkbox
                {...label}
                style={{ marginTop: ".5rem", borderRadius: "6px" }}
                onChange={(e) =>
                  setData({
                    ...data,
                    keep_informed: e.target.checked ? true : false,
                  })
                }
                sx={{
                  color: teal[800],
                  "&.Mui-checked": {
                    color: teal[600],
                  },
                }}
              />
              <label className="logo_label">
                Keep me informed about similar properties.
              </label>
              {/* {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>} */}
            </div>
            <hr className="new_hr_line mt-3 mb-0" />
            <button type="submit" className="Sign_inBtn py-2">
              Send Email
            </button>
          </form>
        </div>
        {/* <button className="report_btn d-flex" type='submit'>Report this Property</button> */}
      </div>
      <Card
        handleOpen={handleOpen}
        open={open}
        setOpen={setOpen}
        profileData={profileData}
      />
      <Call profileData={profileData} open={open1} setOpen={setOpen1} />
    </div>}
    </>
  );
}

export default Banner;
