import React from "react";
import img from "../../Images/seo-banner-img.png";
function SeoSection({ data }) {
    return (
        <div className="container-fluid main_banner_home mt-5 ">
            <div
                style={{ height: "372px", overflow: "hidden" }}
                className="agent mt-5"
            >
                <div className="row m-0" style={{ overflow: "hidden" }}>
                    <div className="col-lg-4 col-md-6 col-12 p-0">
                        <div className="SeoSecImg">
                            <img src={img} alt="..." />
                        </div>
                    </div>
                    <div
                        className="col-lg-8 col-md-6 col-12 p-0 h-100"
                        style={{ overflow: "auto" }}
                    >
                        <div className="agen_content homeComprehen">
                            {data?.map((item) => {
                                return (
                                    <>
                                     <div dangerouslySetInnerHTML={{ __html: item?.head }} />
                                        <div dangerouslySetInnerHTML={{ __html: item?.peraa }} />
                                    </>
                                )
                            })}

                          
                        </div>
                    </div>
                </div>

                {/* <div className="agent_btn mt-5 d-sm-block d-none"></div> */}
            </div>
        </div>
    );
}

export default SeoSection;
