import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../Images/dummy/placeholder.png";
import { MdOutlineBed } from "react-icons/md";
import { LuBath } from "react-icons/lu";
import { FaRegSquare } from "react-icons/fa";
import img2 from "../../Images/dummy/images.png";
import { FallbackImage } from "../FallbackImage";
import NoReacodFound from "../NavBarFooter/NoReacodFound";
function SimilerProertey({ data }) {
  const unitsToRemove = ["Square Yards", "Square Feet", "Square Meters"];
  function formatPrice(value) {
    if (value >= 10000000) {
      return (value / 10000000).toFixed(2) + " Cr";
    } else if (value >= 100000) {
      return (value / 100000).toFixed(2) + " Lakh";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(2) + " Thousand";
    } else {
      return value.toString();
    }
  }
  const AreaDisplay = ({ area }) => {
    const areaString = area?.toString() || "";
    const truncatedArea =
      areaString.length > 4 ? areaString.substring(0, 4) + "..." : areaString;
    return (
      <div className="AreaSize">
        <span >{truncatedArea}</span>
      </div>
    );
  };

  return (
    <>
      <div className="main_similer row my-3">
        <h2>Similar Houses around {data?.property?.location?.name}</h2>
        {data?.similar_properties_by_area?.length ? (
          data?.similar_properties_by_area?.slice(0, 4)?.map((item) => {
            return (
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 my-2">
                <Link
                  to={`/property/${item?.id}/${item?.title?.replace(/ /g, "_")}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="property-item">
                    <div className="position-relative">
                      <div
                        style={{
                          width: "100%",
                          overflow: "hidden",
                          height: "200px",
                        }}
                      >
                        <FallbackImage
                          src={
                            item?.images?.length ? item?.images[0]?.url : img1
                          }
                          alt={"..."}
                          fallbackSrc={img1}
                        />
                        {item?.offer && item?.offer?.label !== "Listing" ? (
                          <>
                            <div
                              style={{ whiteSpace: "nowrap" }}
                              className="position-absolute  pt-1 px-3"
                            >
                              {item?.offer?.label === "Super Hot Listing" ? (
                                <svg
                                  className="me-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="8"
                                  height="10"
                                  viewBox="0 0 8 10"
                                  fill="none"
                                >
                                  <path
                                    d="M8 6.83272C8 8.68831 6.43395 10 4.22642 10C1.7217 10 4.76837e-07 8.43237 4.76837e-07 5.85466C4.76837e-07 1.83272 3.64152 0 6.29716 0C6.71698 0 6.92454 0.201087 6.92454 0.434175C6.92454 0.621573 6.82075 0.776952 6.6651 0.991767C6.28774 1.49451 5.64623 2.22577 5.64623 3.14443C5.64623 3.22212 5.65094 3.29983 5.66038 3.38208C5.92453 2.90219 6.39151 2.56398 6.95754 2.56398C7.11793 2.56398 7.20282 2.6554 7.20282 2.78792C7.20282 2.9479 7.1651 3.06216 7.1651 3.56033C7.1651 4.51553 8 5.15996 8 6.83272ZM4.13207 8.71573C5.07546 8.71573 5.70282 8.1627 5.70282 7.34004C5.70282 6.47623 5.07075 6.17002 4.98584 5.61243C4.98113 5.56672 4.94811 5.55301 4.9151 5.58501C4.67925 5.78153 4.53301 6.02833 4.4198 6.31171C4.39624 6.36198 4.3585 6.36654 4.33489 6.32542C4.07547 5.88666 4.0283 5.23309 4.08962 4.41043C4.09906 4.36472 4.06603 4.34186 4.02359 4.36014C2.90566 4.85375 2.33491 5.92779 2.33491 6.89214C2.33491 7.86107 2.92452 8.71573 4.13207 8.71573Z"
                                    fill="white"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  className="me-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="9"
                                  height="12"
                                  viewBox="0 0 9 12"
                                  fill="none"
                                >
                                  <path
                                    d="M7.70351 5.06049C7.70456 5.78468 7.42766 6.50827 6.87537 7.06041C6.58287 7.35336 6.10828 7.35336 5.81533 7.06041C5.52239 6.76747 5.52239 6.29242 5.81533 5.99993C6.36808 5.44763 6.64437 4.72404 6.64347 4C6.64347 2.43807 6.01168 1.02344 4.98629 0C4.98629 1.17284 4.5117 2.23332 3.74311 2.99996L1.74318 4.99989C0.974294 5.76638 0.5 6.82701 0.5 7.99985C0.5 10.208 2.29188 11.9999 4.5 11.9999C6.70902 11.9999 8.5 10.208 8.5 7.99985C8.5 6.85206 8.1958 5.81243 7.70351 5.06049Z"
                                    fill="white"
                                  />
                                </svg>
                              )}
                              {item?.offer?.label?.replace("Listing", "")}
                            </div>
                            <svg
                              className="svg"
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                id="Vector 2"
                                d="M8 8L0 0H8V8Z"
                                fill="#2797D4"
                              />
                            </svg>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <a href="..." style={{border:"1px solid"}} className='overflow-hidden'></a> */}
                    </div>
                    <div className="p-4 pb-0">
                      <div className="d-flex justify-content-between">
                        <h5>
                          <span>PKR</span> {formatPrice(item?.price)}
                          {/* <span className='price_unit'>Crore</span> */}
                        </h5>
                        {/* <span style={{ border: "1px solid lightgray", height: "50px", width: "50px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%" }}><CiHeart style={{ fontSize: "25px", color: "#2797D4" }} /> </span> */}
                      </div>
                      <h5 title={item?.title} className="d-block h5">
                        {item?.title}
                      </h5>
                      <p
                        title={
                          item?.plot_number +
                          " " +
                          item?.location?.name +
                          " " +
                          item?.city?.name
                        }
                        style={{ color: "gray" }}
                      >
                        {item?.plot_number +
                          " " +
                          item?.location?.name +
                          " " +
                          item?.city?.name}
                      </p>
                    </div>
                    <hr
                      className="p-0 pb-1 m-0 mx-3"
                      style={{ color: "gray" }}
                    />
                    <div className="d-flex property_small">
                      <small
                        style={{ textTransform: "capitalize" }}
                        className="flex-fill text-center py-2 d-flex justify-content-center align-items-center"
                      >
                        <MdOutlineBed
                          style={{
                            color: "#2797D4",
                            fontSize: "20px",
                            margin: "0 .5rem",
                          }}
                        />
                        {item?.bedrooms !== "" && item?.bedrooms !== "undefined"
                          ? item?.bedrooms
                          : 0}{" "}
                        Beds
                      </small>
                      <small className="flex-fill text-center py-2 d-flex justify-content-center align-items-center">
                        <LuBath
                          style={{
                            color: "#2797D4",
                            fontSize: "20px",
                            margin: "0 .5rem",
                          }}
                        />
                        {item?.bathrooms !== "" &&
                        item?.bathrooms !== "undefined"
                          ? item?.bathrooms
                          : 0}{" "}
                        Bathroom
                      </small>
                      <small className="flex-fill text-center py-2 pe-2 d-flex justify-content-center align-items-center">
                        <FaRegSquare
                          style={{
                            color: "#2797D4",
                            fontSize: "20px",
                            margin: "0 .5rem",
                          }}
                        />
                        {<AreaDisplay area={item?.area_size}  />}{" "}
                        {unitsToRemove.reduce((acc, unit) => {
                          return acc.replace(unit, "");
                        }, item?.unit_area || "")}
                      </small>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })
        ) : (
          <NoReacodFound />
        )}
      </div>
      <div className="main_similer row my-3">
        <h2>Similar Houses by {data?.property?.user?.name}</h2>
        {data?.similar_properties_by_vendors?.length ? (
          data?.similar_properties_by_vendors?.slice(0, 4)?.map((item) => {
            return (
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 my-2">
                <Link
                  to={`/property/${item?.id}/${item?.title?.replace(/ /g, "_")}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="property-item">
                    <div className="position-relative">
                      <div
                        style={{
                          width: "100%",
                          overflow: "hidden",
                          height: "200px",
                        }}
                      >
                        <FallbackImage
                          src={
                            item?.images?.length ? item?.images[0]?.url : img1
                          }
                          alt={"..."}
                          fallbackSrc={img1}
                        />
                        {item?.offer && item?.offer?.label !== "Listing" ? (
                          <>
                            <div
                              style={{ whiteSpace: "nowrap" }}
                              className="position-absolute  pt-1 px-3"
                            >
                              {item?.offer?.label === "Super Hot Listing" ? (
                                <svg
                                  className="me-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="8"
                                  height="10"
                                  viewBox="0 0 8 10"
                                  fill="none"
                                >
                                  <path
                                    d="M8 6.83272C8 8.68831 6.43395 10 4.22642 10C1.7217 10 4.76837e-07 8.43237 4.76837e-07 5.85466C4.76837e-07 1.83272 3.64152 0 6.29716 0C6.71698 0 6.92454 0.201087 6.92454 0.434175C6.92454 0.621573 6.82075 0.776952 6.6651 0.991767C6.28774 1.49451 5.64623 2.22577 5.64623 3.14443C5.64623 3.22212 5.65094 3.29983 5.66038 3.38208C5.92453 2.90219 6.39151 2.56398 6.95754 2.56398C7.11793 2.56398 7.20282 2.6554 7.20282 2.78792C7.20282 2.9479 7.1651 3.06216 7.1651 3.56033C7.1651 4.51553 8 5.15996 8 6.83272ZM4.13207 8.71573C5.07546 8.71573 5.70282 8.1627 5.70282 7.34004C5.70282 6.47623 5.07075 6.17002 4.98584 5.61243C4.98113 5.56672 4.94811 5.55301 4.9151 5.58501C4.67925 5.78153 4.53301 6.02833 4.4198 6.31171C4.39624 6.36198 4.3585 6.36654 4.33489 6.32542C4.07547 5.88666 4.0283 5.23309 4.08962 4.41043C4.09906 4.36472 4.06603 4.34186 4.02359 4.36014C2.90566 4.85375 2.33491 5.92779 2.33491 6.89214C2.33491 7.86107 2.92452 8.71573 4.13207 8.71573Z"
                                    fill="white"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  className="me-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="9"
                                  height="12"
                                  viewBox="0 0 9 12"
                                  fill="none"
                                >
                                  <path
                                    d="M7.70351 5.06049C7.70456 5.78468 7.42766 6.50827 6.87537 7.06041C6.58287 7.35336 6.10828 7.35336 5.81533 7.06041C5.52239 6.76747 5.52239 6.29242 5.81533 5.99993C6.36808 5.44763 6.64437 4.72404 6.64347 4C6.64347 2.43807 6.01168 1.02344 4.98629 0C4.98629 1.17284 4.5117 2.23332 3.74311 2.99996L1.74318 4.99989C0.974294 5.76638 0.5 6.82701 0.5 7.99985C0.5 10.208 2.29188 11.9999 4.5 11.9999C6.70902 11.9999 8.5 10.208 8.5 7.99985C8.5 6.85206 8.1958 5.81243 7.70351 5.06049Z"
                                    fill="white"
                                  />
                                </svg>
                              )}
                              {item?.offer?.label?.replace("Listing", "")}
                            </div>
                            <svg
                              className="svg"
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                id="Vector 2"
                                d="M8 8L0 0H8V8Z"
                                fill="#2797D4"
                              />
                            </svg>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <a href="..." style={{border:"1px solid"}} className='overflow-hidden'></a> */}
                    </div>
                    <div className="p-4 pb-0">
                      <div className="d-flex justify-content-between">
                        <h5>
                          <span>PKR</span> {formatPrice(item?.price)}
                          {/* <span className='price_unit'>Crore</span> */}
                        </h5>
                        {/* <span style={{ border: "1px solid lightgray", height: "50px", width: "50px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%" }}><CiHeart style={{ fontSize: "25px", color: "#2797D4" }} /> </span> */}
                      </div>
                      <h5 title={item?.title} className="d-block h5">
                        {item?.title}
                      </h5>
                      <p
                        title={
                          item?.plot_number +
                          " " +
                          item?.location?.name +
                          " " +
                          item?.city?.name
                        }
                        style={{ color: "gray" }}
                      >
                        {item?.plot_number +
                          " " +
                          item?.location?.name +
                          " " +
                          item?.city?.name}
                      </p>
                    </div>
                    <hr
                      className="p-0 pb-1 m-0 mx-3"
                      style={{ color: "gray" }}
                    />
                    <div className="d-flex property_small">
                      <small
                        style={{ textTransform: "capitalize" }}
                        className="flex-fill text-center py-2 d-flex justify-content-center align-items-center"
                      >
                        <MdOutlineBed
                          style={{
                            color: "#2797D4",
                            fontSize: "20px",
                            margin: "0 .5rem",
                          }}
                        />
                        {item?.bedrooms !== "" && item?.bedrooms !== "undefined"
                          ? item?.bedrooms
                          : 0}{" "}
                        Beds
                      </small>
                      <small className="flex-fill text-center py-2 d-flex justify-content-center align-items-center">
                        <LuBath
                          style={{
                            color: "#2797D4",
                            fontSize: "20px",
                            margin: "0 .5rem",
                          }}
                        />
                        {item?.bathrooms !== "" &&
                        item?.bathrooms !== "undefined"
                          ? item?.bathrooms
                          : 0}{" "}
                        Bathroom
                      </small>
                      <small className="flex-fill text-center py-2 pe-2 d-flex justify-content-center align-items-center">
                        <FaRegSquare
                          style={{
                            color: "#2797D4",
                            fontSize: "20px",
                            margin: "0 .5rem",
                          }}
                        />
                        {<AreaDisplay area={item?.area_size} />}{" "}
                        {unitsToRemove.reduce((acc, unit) => {
                          return acc.replace(unit, "");
                        }, item?.unit_area || "")}
                      </small>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })
        ) : (
          <NoReacodFound />
        )}
      </div>
    </>
  );
}

export default SimilerProertey;
