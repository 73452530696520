import React from 'react'
import img from '../../Images/Best-Banks-in-Pakistan.jpg'
import img2 from '../../Images/Real-State-Agent.jpg'
import img3 from '../../Images/Square-Mall.jpg'
import img1 from '../../Images/Group 1000014178.png'
import { GoArrowUpRight } from 'react-icons/go'
import { GrInstagram, GrLinkedinOption } from 'react-icons/gr'
import { FaFacebookF, FaTwitter } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
function BlogListing() {
    const navigate = useNavigate()
    return (
        <>
            <div className='blog-listing'>
                <div className='row m-0 '>
                    <div className='col-lg-9 col-md-12 col-12 list-col'>
                        <div className='row p-2'>
                            <div className='col-lg-6 col-12 px-3'>
                                <div onClick={() => {
                                    navigate("/blog-detail-2")
                                }} className='Interior-card' style={{ cursor: "pointer" }}>
                                    <div className='Interior-img'>
                                        <div className='img-heading'>
                                            <h4>Interior Design</h4>
                                        </div>
                                        <img src={img} alt='' />
                                    </div>
                                    <div className='listing-heading'>
                                        <h3>The Top 18 Best Banks in Pakistan for 2025: A Complete Guide</h3>
                                        <p>When it comes to choosing the <b>Best Bank in Pakistan</b>, there are several factors to consider, from customer service and digital banking options to investment opportunities and loan offerings. As we step into 2025, the Pakistani banking sector has seen substantial transformations, making it even more important for consumers to select the right financial partner. Whether you’re looking for savings, car loans, investment options, or simply the <b>best banking services in Pakistan</b>, this comprehensive guide is crafted to help you make informed decisions.</p>
                                        <div className='list-span'>
                                            <div>
                                                <h2>Pakistan Property</h2>
                                                <h3 style={{ marginTop: '-7px' }}>March 16,2024.6 min read</h3>
                                            </div>
                                            <div className='read-more'>
                                                <h3 style={{ color: '#1877F2', cursor: "pointer" }}>Read More<span><GoArrowUpRight /></span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='col-lg-6 col-12 px-3'>
                                <div onClick={() => {
                                    navigate("/blog-detail-3")
                                }} className='Interior-card' style={{ cursor: "pointer" }}>
                                    <div className='Interior-img'>
                                        <div className='img-heading'>
                                            <h4>Interior Design</h4>
                                        </div>
                                        <img src={img2} alt='' />
                                    </div>
                                    <div className='listing-heading'>
                                        <h3>Difference Between Commercial and Residential Real Estate Agent: A Guide</h3>
                                        <p>n the ever-evolving landscape of the property market, the roles of real estate professionals are more diverse than ever. Among the most prominent divisions lies the distinction between commercial and residential agents. While both operate under the broader umbrella of property sales and management, their duties, clientele, and approaches differ significantly. Understanding the <b>difference between commercial and residential real estate agent</b> roles is crucial whether you're planning a career in the field or seeking property services in Pakistan.</p>
                                        <div className='list-span'>
                                            <div>
                                                <h2>Pakistan Property</h2>
                                                <h3 style={{ marginTop: '-7px' }}>March 16,2024.6 min read</h3>
                                            </div>
                                            <div className='read-more'>
                                                <h3 style={{ color: '#1877F2', cursor: "pointer" }}>Read More<span><GoArrowUpRight /></span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12 px-3 mt-5'>
                                <div onClick={() => {
                                    navigate("/blog-detail-4")
                                }} className='Interior-card' style={{ cursor: "pointer" }}>
                                    <div className='Interior-img'>
                                        <div className='img-heading'>
                                            <h4>Interior Design</h4>
                                        </div>
                                        <img src={img3} alt='' />
                                    </div>
                                    <div className='listing-heading'>
                                        <h3>Why Madison Square Mall Lahore Is the Top Property Investment of 2025</h3>
                                        <p>Lahore's urban landscape is witnessing a transformation, and one development is capturing the spotlight with bold ambition and unmatched potential: <b>Madison Square Mall</b>. More than just a mall, this project is setting a new standard for luxury, commerce, and smart investment in Pakistan. If you're looking for the most lucrative real estate opportunity of 2025, here's why <b>Madison Square Mall</b> deserves your attention.</p>
                                        <div className='list-span'>
                                            <div>
                                                <h2>Pakistan Property</h2>
                                                <h3 style={{ marginTop: '-7px' }}>March 16,2024.6 min read</h3>
                                            </div>
                                            <div className='read-more'>
                                                <h3 style={{ color: '#1877F2', cursor: "pointer" }}>Read More<span><GoArrowUpRight /></span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-12 col-12 p-0'>
                        <div className='side-card'>
                            <div className='upr-section'>
                                <h2>Follow Us</h2>
                                <div className='blog-icon'>
                                    <div><FaFacebookF /></div>
                                    <div><FaTwitter /></div>
                                    <div><GrLinkedinOption /></div>
                                    <div><GrInstagram /></div>
                                </div>
                            </div>
                            <div className='lower-section'>
                                <div className='lower-img'>
                                    <img src={img1} alt='' />
                                </div>
                                <h3>Newsletter</h3>
                                <p>Subscribe For Daily Blog Alert</p>
                                <div className='pading'>
                                    <div className='lower-input'>
                                        <h5>Full Name</h5>
                                        <input type='' placeholder='Last Name' />
                                    </div>
                                    <div style={{ marginTop: '25px' }} className='lower-input'>
                                        <h5>Email</h5>
                                        <input type='' placeholder='maherarslan7022gmail.com' />
                                    </div>
                                    <div className='Blog-button'>
                                        <button>Subscribe</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BlogListing