import React from 'react'
import img from '../../Images/Real-State-Company.jpg'
import { GoArrowUpRight } from 'react-icons/go'
import { useNavigate } from 'react-router-dom'
function Banner() {
    const navigate=useNavigate()
  return (
   <>
   <div className='blogs px-4 my-4'>
    <div onClick={()=>{
        navigate('/blog-detail-1')
    }} className='row m-0'>
        <div className='col-lg-8 col-12 p-0'>
        <div className='blog-card border'>
            <img src={img} alt=''/>
        </div>
        </div>
        <div className='col-lg-4 col-12 p-0'>
            <div className='blog-text'>
                <h3>Top 15 Real Estate Companies in Pakistan for 2025</h3>
                <span style={{color:'#2797D4'}} className='d-flex'>Pakistan Property.<p style={{color:'#959595'}}>March 16,2022</p></span>
                <p><b>Top 15 Real Estate Companies in Pakistan</b> have played a major role in the country’s property sector, which has experienced significant evolution over the past decade, offering lucrative investment opportunities, urban development, and economic growth. As of 2025, the sector remains the backbone of the national economy, drawing interest from local and international investors. This blog lists the <b>Top 15 Real Estate Companies in Pakistan</b> that have established trust, innovation, and quality over time. The companies are selected based on their reputation, project completion rate, legal transparency, and customer satisfaction.
                </p>
                <h6>Read More<span style={{fontSize:'19px',marginLeft:'3px'}}><GoArrowUpRight /></span></h6>
            </div>
        </div>
    </div>
   </div>
   </>
  )
}

export default Banner
