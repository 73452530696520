import React, { useEffect, useState } from 'react'
import {  useNavigate } from 'react-router-dom'
import { useAuth } from '../../Context/ContextProvider'
import { FallbackImage } from '../FallbackImage'
import img from '../../Images/dummy/placeholder.png'
import bahawalpur from '../../Images/Agents/Bahawalpur.jpeg'
import faislaabad from '../../Images/Agents/Faislaabad.jpg'
import chitral from '../../Images/Agents/chitral.jpg'
import multan from '../../Images/Agents/Mask group (2).png'
import daska from '../../Images/Agents/daska.jpg'
import kasur from '../../Images/Agents/kasur.jpg'
import hafizabad from '../../Images/Agents/hafizabad.jpg'
import gujranwala from '../../Images/Agents/gujranwala.jpg'
import narowal from '../../Images/Agents/narowal.jpg'
import sheikhupura from '../../Images/Agents/Sheikhupura.jpg'
import lahore from '../../Images/Agents/Rectangle 17.png'

function NeighborhoodProperties() {
  const {propertyListCity,setPropertyList,neighbouringProperty,setCurrentCity,data,setData}=useAuth()
  const navigate=useNavigate()
  useEffect(()=>{
    const fetchData = async () => {
      try {
        let result = await neighbouringProperty();
        if (result?.success) {
          const propertyTypes = result.data.data.properties;
          setPropertyList(propertyTypes)
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    }
    if(!propertyListCity?.length){
      fetchData()
    }
  },[])
  return (
    <div className='neighborhood_properties  container-fluid main_banner_home mt-5'>
      <h2 className='mt-2 mb-2 text-sm-start text-center neughb'>Neighborhood Properties</h2>
      <div className="row">
      {propertyListCity?.map((item)=>{
        return(
          <div className='col-lg-4 col-md-6 col-12 my-2 mb-3'>
          <div className='main_card neighborCity'>
            <div className='img'>
             {item?.city==="Lahore"&&<FallbackImage src={lahore} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Daska"&&<FallbackImage src={daska} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Multan"&&<FallbackImage src={multan} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Faisalabad"&&<FallbackImage src={faislaabad} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Chitral"&&<FallbackImage src={chitral} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Bahawalpur"&&<FallbackImage src={bahawalpur} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Sheikhupura"&&<FallbackImage src={sheikhupura} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Kasur"&&<FallbackImage src={kasur} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Gujranwala"&&<FallbackImage src={gujranwala} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Narowal"&&<FallbackImage src={narowal} fallbackSrc={img} alt={"...."}/>}
              {item?.city==="Hafizabad"&&<FallbackImage src={hafizabad} fallbackSrc={img} alt={"...."}/>}
            </div>
            <div className='content_neignor '>
              <h2 className='total_properties'>{item?.total_properties}</h2>
              <h5>{item?.city}</h5>
              <p style={{cursor:"pointer"}} onClick={async()=>{
                setData({...data,city_code:item?.city_code})
                setCurrentCity({ city_name:item?.city,
                  app_code:item?.city_code})
                  navigate(`/property/property-for-sell`)
              }} className='link'>Explore Properties <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                <path d="M0.732666 10L10.7327 1M10.7327 1H1.73267M10.7327 1V9.1" stroke="#1877F2" stroke-linecap="round" stroke-linejoin="round" />
              </svg></p>
            </div>
          </div>
        </div>
        )
      })}
      </div>
     
    </div>
  )
}

export default NeighborhoodProperties
